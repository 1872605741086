import { IServiceManager, RootAppLifecycleService } from './types';
/**
 * Name of the default namespace
 */
const DEFAULT_NAMESPACE = '$default';

export class ServiceManager implements IServiceManager {
  /**
   * Services namespaces container
   */
  private services: Record<string, Record<string, unknown>> = {
    [DEFAULT_NAMESPACE]: {},
  };

  /**
   * Adds a service on the container
   *
   * @param namespaceContainer - object that contains the services
   * @param serviceName - the name of the service
   * @param service - the service
   */
  private addServiceToContainer<T>(
    namespaceContainer: Record<string, T>,
    serviceName: string,
    service: T
  ) {
    if (!namespaceContainer[`$${serviceName}`]) {
      namespaceContainer[`$${serviceName}`] = service;
    }
  }

  /**
   * Gets the namespace container. Creates it if it does not exist.
   *
   * @param namespace - namespace to retrieve
   * @returns the namespace
   */
  private getNamespace<T>(namespace: string): Record<string, T> {
    const ns = (this.services[namespace] = (this.services[namespace] ?? {}) as Record<string, T>);
    return ns;
  }

  /**
   * @inheritdoc
   */
  public getService<T>(serviceName: string): T {
    // Assume that the serviceName will give expected type
    const service = this.services[DEFAULT_NAMESPACE][`$${serviceName}`] as T;
    if (service === undefined || service === null) {
      throw new Error(`Service ${serviceName} not found`);
    }

    return service;
  }

  /**
   * @inheritdoc
   */
  public getNamespacedService<T>(namespace: string, serviceName: string): T {
    const ns = this.getNamespace<T>(namespace);
    if (ns === undefined || ns === null) {
      throw new Error(`Service ${serviceName} not found in namespace ${namespace}`);
    }

    return ns[`$${serviceName}`];
  }

  /**
   * @inheritdoc
   */
  public addService<T>(serviceName: string, service: T): void {
    this.addServiceToContainer(this.services[DEFAULT_NAMESPACE], serviceName, service);
  }

  /**
   * @inheritdoc
   */
  public addNamespacedService<T>(namespace: string, serviceName: string, service: T): void {
    const ns = this.getNamespace<T>(namespace);

    this.addServiceToContainer<T>(ns, serviceName, service);
  }

  /**
   * @inheritdoc
   */
  public getNamespaceServices<T>(namespace: string): T[] {
    const ns = this.getNamespace<T>(namespace);

    return ns ? Object.values(ns) : [];
  }

  /**
   * @inheritdoc
   */
  onRootAppMount(): void {
    Object.values(this.services).forEach((namespace) => {
      Object.values(namespace as Record<string, RootAppLifecycleService>).forEach((service) => {
        void service.onRootAppMount?.();
      });
    });
  }
}
