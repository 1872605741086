import { INITIAL_DEFAULT_LOCALE, INITIAL_FALLBACK_LOCALE } from '../../services/i18nManager';
import { PersistKeys } from '../plugins/persistence';

export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_SELECTED_LOCALE = 'SET_SELECTED_LOCALE';
export const SET_FALLBACK_LOCALE = 'SET_FALLBACK_LOCALE';
export const SET_LOADING = 'SET_LOADING';
export const SET_LOAD_ERROR = 'SET_LOAD_ERROR';

export const NAMESPACE = 'languages';

/**
 * Notifications store module.
 */
export const LanguagesModule = {
  namespaced: true,
  state: {
    [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
    languages: [],
    selectedLocale: INITIAL_DEFAULT_LOCALE,
    fallbackLocale: INITIAL_FALLBACK_LOCALE,
    loading: true,
    loadError: false,
  },

  getters: {
    /**
     * @returns {object[]} the languages
     */
    languages: (state) => state.languages,
    /**
     * @returns {string} the current locale
     */
    selectedLocale: (state) => state.selectedLocale,
    /**
     * @returns {string} the fallback locale
     */
    fallbackLocale: (state) => state.fallbackLocale,
    /**
     * @returns {boolean} if loading the languages
     */
    loadingLanguages: (state) => state.loading,
    /**
     * @returns {boolean} if an error occurred loading the languages
     */
    loadLanguagesError: (state) => state.loadError,
  },

  actions: {},

  mutations: {
    /**
     * Updates the languages
     *
     * @param {Object} state - Store state
     * @param {object[]} languages - the interface languages
     */
    [SET_LANGUAGES](state, languages) {
      state.languages = languages;
    },
    /**
     * Updates the locale
     *
     * @param {Object} state - Store state
     * @param {string} locale - the new locale
     */
    [SET_SELECTED_LOCALE](state, locale) {
      state.selectedLocale = locale;
    },
    /**
     * Updates the fallback locale
     *
     * @param {Object} state - Store state
     * @param {string} locale - the new locale
     */
    [SET_FALLBACK_LOCALE](state, locale) {
      state.fallbackLocale = locale;
    },
    /**
     * Updates the loading state
     *
     * @param {Object} state - Store state
     * @param {Boolean} loading - if loading languages
     */
    [SET_LOADING](state, loading) {
      state.loading = loading;
    },
    /**
     * Updates the error state
     *
     * @param {Object} state - Store state
     * @param {Boolean} error - if an error occurred
     */
    [SET_LOAD_ERROR](state, error) {
      state.loadError = error;
    },
  },
};
