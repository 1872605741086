<template>
  <pui-common-input-tag
    v-bind="$attrs"
    :items="limitedOptions"
    hideInput
    :getItemId="getItemId"
    :class="rootClass"
    :style="rootStyle"
    :loading="isLoading"
    v-on="$listeners"
    @clearSelection="onClearSelection"
  >
    <!-- pass slots to child -->
    <template v-for="(_, slotIndex) of $scopedSlots" #[slotIndex]="slotProps">
      <slot :name="slotIndex" v-bind="slotProps" />
    </template>

    <template #inputContent>
      <slot name="placeholder">
        <pui-flex alignItems="center" class="attribute-input-tag__placeholder">
          <span v-if="showNoOptions && !options.length">
            {{ noOptionsMessage }}
          </span>
          <span v-else-if="placeholder">
            {{ placeholder }}
          </span>
        </pui-flex>
      </slot>
    </template>

    <template #item="{ item }">
      <pui-chip outlined class="attribute-input-tag__item" @click="toggleItem(item)">
        <template #default>
          <pui-flex class="attribute-input-tag-item-content">
            <pui-checkbox
              class="attribute-input-tag__checkbox"
              :checked="isItemChecked(item)"
              :disabled="disabled"
              @change="toggleItem(item)"
            />
            <div class="text">
              {{ display ? display(item) : item }}
            </div>
          </pui-flex>
        </template>
      </pui-chip>
    </template>
  </pui-common-input-tag>
</template>

<script>
export default {
  props: {
    /**
     * Max number of options to display
     */
    optionsLimit: {
      type: Number,
      default: null,
    },
    /**
     * The max amount of options that can be selected
     */
    max: {
      type: [Number, Boolean],
      default: false,
    },
    /**
     * The array of currently selected items
     */
    value: {
      type: Array,
      default: () => [],
    },
    /**
     * The options to display
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * Function to format the display of an item
     */
    display: {
      type: Function,
      default: null,
    },
    /**
     * Number of lines to show
     */
    lineCount: {
      type: Number,
      default: null,
    },
    /**
     * Disables the checkboxes
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder for the input
     */
    placeholder: {
      type: String,
      default: null,
    },
    /**
     * Will change the placeholder to the string given by 'noOptionsMessage'
     */
    showNoOptions: {
      type: Boolean,
      default: false,
    },
    /**
     * Placeholder to display if 'showNoOptions'
     */
    noOptionsMessage: {
      type: String,
      default: 'Aucun élément',
    },
    /**
     * Will display a spinner inside the input instead of the options
     */
    isLoading: {
      type: Boolean,
      default: false,
    },
    /**
     * On selecing an item, will add it to selection instead
     * of replacing the currently selected item
     */
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedItems:
        this.value != null ? (Array.isArray(this.value) ? this.value : [this.value]) : [],
    };
  },
  computed: {
    /**
     * @returns {object} class object for the root element
     */
    rootClass() {
      return {
        'attribute-input-tag__root': true,
        setLineCount: this.lineCount !== null,
      };
    },
    /**
     * @returns {object} style object for the root element
     */
    rootStyle() {
      return {
        '--line-count': this.lineCount,
      };
    },
    /**
     * @returns {Array} the array of limited options or all of them
     */
    limitedOptions() {
      if (this.optionsLimit !== null) {
        return this.options.slice(0, this.optionsLimit);
      }

      return this.options;
    },
  },
  watch: {
    value() {
      this.selectedItems =
        this.value != null ? (Array.isArray(this.value) ? this.value : [this.value]) : [];
    },
  },
  methods: {
    /**
     * @param {object} item - an option
     * @param {number} itemIndex - the index of the item in the array
     * @returns {string} a unique id for each item
     */
    getItemId(item, itemIndex) {
      return item.itemId;
    },
    /**
     * @param {object} item - an option
     * @returns {boolean} if the item is in our values
     */
    isItemChecked(item) {
      return !!this.selectedItems.find((checkedItem) => checkedItem.itemId === item.itemId);
    },
    /**
     * Adds or removes the item from our selected items
     * and emits an update
     *
     * @param {object} item - an option
     * @returns {void}
     */
    toggleItem(item) {
      if (this.disabled) {
        return;
      }

      const existingIndex = this.selectedItems.findIndex(
        (checkedItem) => checkedItem.itemId === item.itemId
      );
      if (existingIndex >= 0) {
        this.$emit('remove', item);
        this.selectedItems.splice(existingIndex, 1);
      } else {
        if (this.multiple) {
          if (this.max && this.selectedItems.length === this.max) {
            return;
          }

          this.$emit('select', item);
          this.selectedItems.push(item);
        } else {
          this.$emit('select', item);
          this.selectedItems = [item];
        }
      }

      this.$emit('listUpdated', this.selectedItems);
    },
    /**
     * Clears our selected items and emits an update
     *
     * @returns {void}
     */
    onClearSelection() {
      this.selectedItems = [];
      this.$emit('selectionCleared', this.selectedItems);
    },
  },
};
</script>
