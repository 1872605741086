import moment from 'moment';

import services from '../services';
import { I18nManager } from '../services/i18nManager';
import { ILanguagesManagerService } from '../services/types';
import { SortFunction } from './types';

/**
 * A function that compares a to b
 */
export type CompareFunc<A = unknown, B = unknown> = (a: A, b: B) => number;

/**
 * Creates an order-configurable sort function from a base compare function
 * @param fn - the compare function
 * @returns a new function that can switch the compare order
 */
function makeSortFunction<A = unknown, B = unknown>(fn: CompareFunc<A, B>): SortFunction<A, B> {
  return (a, b, order) => {
    const res = fn(a, b);
    return order === 'asc' ? res : res * -1;
  };
}

/**
 * Sorts string elements
 */
const sortAlphanumeric = makeSortFunction((a: string, b: string) => {
  return a
    ?.toString()
    .toLocaleLowerCase()
    .localeCompare(
      b?.toString().toLocaleLowerCase(),
      I18nManager.getI18nLocale(
        services.getService<ILanguagesManagerService>('languages')!.getSelectedLocale()
      ),
      { sensitivity: 'base' }
    );
});

/**
 * Sorts numeric elements
 */
const sortNumeric = makeSortFunction((a: number, b: number) => {
  return a - b;
});

/**
 * Sorts boolean elements
 */
const sortBoolean = makeSortFunction((a: boolean, b: boolean) => {
  if (a === b) {
    return 0;
  }
  return a ? 1 : -1;
});

/**
 * Sorts date elements
 */
const sortDate = makeSortFunction((a: string, b: string) => {
  const aDate = moment(a);
  const bDate = moment(b);

  if (aDate.isBefore(bDate)) {
    return -1;
  }
  if (aDate.isAfter(bDate)) {
    return 1;
  }
  return 0;
});

/**
 * The core sort functions
 */
export const sortFns = [
  {
    name: 'alpha',
    func: sortAlphanumeric,
  },
  {
    name: 'numeric',
    func: sortNumeric,
  },
  {
    name: 'boolean',
    func: sortBoolean,
  },
  {
    name: 'date',
    func: sortDate,
  },
];
