<template>
  <div class="pui-attribute-table-indicators pui-attribute-table-indicators__root">
    <div
      v-if="dnd.target != -1"
      :style="{ top: `${dnd.insertIndicator.y - 1}px` }"
      class="pui-attribute-table-indicators__target-indicator"
    ></div>

    <div
      v-if="dnd.target != -1"
      :style="{
        top: `${dnd.ghostRowIndicator.y}px`,
        height: `${dnd.ghostRowIndicator.height}px`,
      }"
      class="pui-attribute-table-indicators__dragging-indicator"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'PuiAttrTableIndicator',
  props: {
    /**
     * Attribute table dnd state object
     */
    dnd: {
      type: Object,
      required: true,
    },
  },
};
</script>
