import Vue from 'vue';
import Vuex from 'vuex';

import { CoreModule } from './modules/coreModule';
import { LanguagesModule, NAMESPACE as LANGUAGES_NAMESPACE } from './modules/languages';
import { NAMESPACE as VERSIONS_NAMESPACE, VersionsModule } from './modules/versions';
import plugins from './plugins';

Vue.use(Vuex);

const store = new Vuex.Store({
  ...CoreModule,
  plugins,
  modules: {
    [LANGUAGES_NAMESPACE]: LanguagesModule,
    [VERSIONS_NAMESPACE]: VersionsModule,
  },
});

export default store;
