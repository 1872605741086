import Vue from 'vue';

import { Label } from '../../../core/types/i18n';
import { Response } from './../../../core/types/http';
import { AttributeTypes } from './../constants/index';

export namespace AttributeValues {
  export interface ExternalLinkValueItemResource {
    url?: string;
    path?: string;
    storage?: string;
  }
  export interface ExternalLinkValueItem {
    id: string;
    name: string;
    externalId?: any;
    position: number;
    resource: ExternalLinkValueItemResource;
    thumbnail: ExternalLinkValueItemResource;
    preview: ExternalLinkValueItemResource;
  }

  export interface ComboValueItem {
    data: {
      id_op: number;
      libelle_op: string;
      code_op: string;
      date_deb_op: string;
      date_fin_op: string;
    };
    label: Label;
    itemId: string;
    alias: string;
  }

  /**
   * Either the option item, or simply the alias of the option
   */
  export type ComboValue = (ComboValueItem | string)[];

  /**
   * Tables contain lines of columns, where each cell is a normal attribute value
   */
  export type TableValue = AttributeValue[][];

  /**
   * The possible scalar value types of an attribute
   */
  export type ScalarValue = boolean | number | string | null;

  /**
   * The possible compound value types of an attribute
   */
  export type CompoundValue = ComboValue | TableValue | ExternalLinkValueItem[];

  /**
   * All the value types of an attribute
   */
  export type AttributeValue = ScalarValue | CompoundValue;

  export interface ValueObject {
    attributeAlias: string;
    attributeId: string | null;
    value: AttributeValue;
    type: AttributeTypes;
  }

  /**
   * Option value object for an attribute
   * Ex: Links options
   */
  export type AttributeOptionValue = {
    label: Label;
    itemId: string;
    alias: string;
    children?: AttributeOptionValue[];
  };
}

const ATTRIBUTES_API_URL = '/api/piivo/v1/attributes';

export default {
  async importAttributeValue(
    attributeId: string,
    file: File,
    beforeRequestCb: ((request: unknown) => void) | null = null
  ): Response<AttributeValues.ValueObject> {
    const formData = new FormData();
    formData.append('myFile', file);

    return Vue.http.post(`${ATTRIBUTES_API_URL}/${attributeId}/values/import`, formData, {
      before(request: unknown) {
        if (beforeRequestCb) {
          beforeRequestCb(request);
        }
      },
    }) as Response<AttributeValues.ValueObject>;
  },

  /**
   * Get possible option values for an attribute
   *
   * @param attributeId - The template identifier.
   * @param bodyParameters - The optional body parameters.
   */
  async getPossibleValues<OptionValue extends AttributeValues.AttributeOptionValue>(
    attributeId: string,
    bodyParameters: Map<string, unknown>
  ): Response<OptionValue[]> {
    return Vue.http.post(
      `${ATTRIBUTES_API_URL}/${attributeId}/values`,
      Object.fromEntries(bodyParameters || [])
    ) as Response<OptionValue[]>;
  },
};
