export enum TrimModes {
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
  NONE = 'none',
}

/**
 * Trim a string with the mode in parameter.
 * @param value - Strim to trim
 * @param mode - Trim mode
 */
export const trimWithMode = (value: string, mode: TrimModes | null = null) => {
  if (mode !== TrimModes.NONE) {
    switch (mode) {
      case TrimModes.LEFT:
        value = value.trimStart();
        break;
      case TrimModes.RIGHT:
        value = value.trimEnd();
        break;
      case TrimModes.BOTH:
      default:
        // Default mode is BOTH (left and right trim)
        value = value.trim();
        break;
    }
  }

  return value;
};
