<template>
  <pui-input
    ref="editor"
    v-model="getValue"
    type="date"
    :disabled="editor.disabled"
    @change="onValueChanged"
    @blur="onBlur"
  >
  </pui-input>
</template>
<script>
import TableAttributeEditorMixin from './TableAttributeEditorMixin';

export default {
  components: {},
  mixins: [TableAttributeEditorMixin],
};
</script>
