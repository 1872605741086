<template>
  <p class="boolean-renderer renderer-string-content">{{ displayBoolean(value) }}</p>
</template>
<script>
import Vue from 'vue';

import TableAttributeRendererMixin from './TableAttributeRendererMixin';

export default {
  mixins: [TableAttributeRendererMixin],
  methods: {
    displayBoolean(value) {
      return Vue.filter('displayBoolean')(value);
    },
  },
};
</script>
