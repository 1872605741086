export default {
  props: {
    /**
     * Renderer value (cell value).
     */
    value: {},
    /**
     * Value formatter.
     */
    formatter: {},
  },
};
