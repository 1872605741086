export let _Vue;

export function install(Vue, settings) {
  if (install.installed && _Vue === Vue) return;
  install.installed = true;
  _Vue = Vue;
  Vue.mixin({ beforeCreate: helperInit });

  function helperInit() {
    const options = this.$options;
    if (options.helpers) {
      this.$helpers = typeof options.helpers === 'function' ? options.helpers() : options.helpers;
    } else if (options.parent && options.parent.$helpers) {
      this.$helpers = options.parent.$helpers;
    }
  }
}
