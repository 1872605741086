<template>
  <div
    v-if="isVisible"
    class="pui-attribute-panel pui-attribute-panel__root"
    :data-id="attribute.alias"
  >
    <!-- ATTRIBUTE STRING AND NOT MULTIPLE -->
    <pui-input
      v-if="isSingleStringVisible"
      ref="attributeField"
      :title="value"
      :hasSearch="hasSearch"
      :required="isRequired"
      :disabled="isDisabled"
      :showLabel="showLabel"
      :label="label"
      :textarea="rows > 1"
      :rows="rows"
      :minLength="minLength"
      :value="inputValue"
      :placeholder="placeholder"
      :class="{
        'pui-attribute-panel-field pui-attribute-panel__input': true,
        'pui-form-group-search': hasSearch && !isDisabled,
      }"
      :resize="resize"
      :tabindex="tabindex"
      type="text"
      @input="onInput($event)"
      @change="setValue"
      @keydown.enter="$emit('enter', $event)"
      @blur="onBlur"
      @keyup="onKeyUp"
      @focus="$emit('focus', $event)"
    >
      <pui-flex class="pui-attribute-panel__messages-container" direction="column">
        <pui-flex class="pui-attribute-panel__message top" flex="1">
          <div class="pui-attribute-panel__message left">
            <pui-input-message
              v-if="activateValidationMessages"
              :visible="isRequired"
              :message="$t('common.attribute_panel.required')"
            />
            <pui-input-message
              v-if="activateValidationMessages"
              :visible="inputValue != null && inputValue.length > 0 && errorMinChars"
              :message="$t('common.attribute_panel.min_length', { minLength })"
            />
            <pui-input-message
              v-if="activateValidationMessages"
              :visible="inputValue != null && inputValue.length > 0 && errorMaxChars"
              :message="$t('common.attribute_panel.max_length', { maxLength })"
            />
          </div>
          <div v-if="showCharacterCounter" class="pui-attribute-panel__message right">
            <div v-if="showCharacterCounter" class="max-length-counter">
              <template v-if="maxLength">
                {{
                  $t('common.attribute_panel.max_length_counter', {
                    value: (inputValue || '').length,
                    maxLength,
                  })
                }}
              </template>
              <template v-else>
                {{ (inputValue || '').length }}
              </template>
            </div>
          </div>
        </pui-flex>
        <pui-flex class="pui-attribute-panel__message bottom" flex="1">
          <div class="search-text-link">
            <a v-if="hasSearch && !isDisabled" href="#" @click.prevent="onSearch"
              ><span :class="`picto mdi ${searchLinkPicto}`" />{{
                $t('common.attribute_panel.search')
              }}</a
            >
          </div>
        </pui-flex>
      </pui-flex>
    </pui-input>

    <!-- ATTRIBUTE STRING AND MULTIPLE -->
    <pui-common-input-tag
      v-if="isMultiStringVisible"
      ref="attributeField"
      :title="value"
      :items="inputValue"
      :hideClearButton="!clearable"
      :label="label"
      :showLabel="showLabel"
      :itemLabel="itemLabel"
      :minItems="minimumItems"
      :maxItems="maximumItems"
      :disabled="isDisabled"
      :showClearButtonWhenDisabled="true"
      :class="{
        'pui-attribute-panel-field pui-attribute-panel__multistring pui-form-group-search': true,
      }"
      :tabindex="tabindex"
      :simple="isSimpleMultiString"
      :hideInput="true"
      @clearSelection="listUpdated([])"
      @removeItem="onRemoveItem"
      @focus="$emit('focus', $event)"
    >
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="isRequired"
        :message="$t('common.attribute_panel.required')"
      />
      <div class="search-text-link">
        <a v-if="hasSearch && !isDisabled" href="#" @click.prevent="onSearch">
          <span :class="`picto mdi ${searchLinkPicto}`" />
          {{ $t('common.attribute_panel.multi_string.add', { itemLabel }) }}
        </a>
      </div>
    </pui-common-input-tag>

    <!-- ATTRIBUTE NUMERIC -->
    <pui-input
      v-else-if="isNumericVisible"
      ref="attributeField"
      :title="value"
      :hasSearch="hasSearch"
      :required="isRequired"
      :disabled="isDisabled"
      :showLabel="showLabel"
      :label="label"
      :min="min"
      :max="max"
      :step="step"
      :value="inputValue"
      :formatter="numberFormatter"
      :lazyFormatter="true"
      :placeholder="placeholder"
      :class="{
        'pui-attribute-panel-field pui-attribute-panel__numeric': true,
        'pui-form-group-search': hasSearch && !isDisabled,
      }"
      :tabindex="tabindex"
      type="number"
      @input="onInput($event)"
      @change="setValue($event)"
      @keydown.enter="$emit('enter', $event)"
      @blur="onBlur"
      @keyup="onKeyUp"
      @focus="$emit('focus', $event)"
    >
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="isRequired"
        :message="$t('common.attribute_panel.required')"
      />
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="errorMinNumeric"
        :message="$t('common.attribute_panel.min', { min })"
      />
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="errorMaxNumeric"
        :message="$t('common.attribute_panel.max', { max })"
      />
      <div class="search-text-link">
        <a v-if="hasSearch && !isDisabled" href="#" @click.prevent="onSearch"
          ><span class="picto mdi mdi-magnify" />{{ $t('common.attribute_panel.search') }}</a
        >
      </div>
    </pui-input>

    <!-- ATTRIBUTE DATE -->
    <pui-input
      v-else-if="isDateVisible"
      ref="attributeField"
      :hasSearch="hasSearch"
      :required="isRequired"
      :disabled="isDisabled"
      :showLabel="showLabel"
      :label="label"
      :value="inputValue"
      :title="inputValue"
      :placeholder="placeholder"
      :class="{
        'pui-attribute-panel-field pui-attribute-panel__date': true,
        'pui-form-group-search': hasSearch && !isDisabled,
      }"
      :tabindex="tabindex"
      type="date"
      @input="onInput($event)"
      @change="setValue($event)"
      @keydown.enter="$emit('enter', $event)"
      @blur="onBlur"
      @keyup="onKeyUp"
      @focus="$emit('focus', $event)"
    >
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="isRequired"
        :message="$t('common.attribute_panel.required')"
      />
      <div class="search-text-link">
        <a v-if="hasSearch && !isDisabled" href="#" @click.prevent="onSearch"
          ><span class="picto mdi mdi-magnify" />{{ $t('common.attribute_panel.search') }}</a
        >
      </div>
    </pui-input>

    <!-- ATTRIBUTE DATE-TIME -->
    <pui-date
      v-else-if="isDateTimeVisible"
      ref="attributeField"
      :required="isRequired"
      :disabled="isDisabled"
      :showLabel="showLabel"
      :label="label"
      :value="inputValue"
      :title="inputValue"
      :placeholder="placeholder"
      :class="{
        'pui-attribute-panel-field pui-attribute-panel__datetime': true,
        'pui-form-group-search': hasSearch && !isDisabled,
      }"
      :tabindex="tabindex"
      :options="{ enableTime: true }"
      @change="setValue($event)"
      @blur="onBlur"
      @focus="$emit('focus', $event)"
    >
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="isRequired"
        :message="$t('common.attribute_panel.required')"
      />
      <div class="search-text-link">
        <a v-if="hasSearch && !isDisabled" href="#" @click.prevent="onSearch"
          ><span class="picto mdi mdi-magnify" />{{ $t('common.attribute_panel.search') }}</a
        >
      </div>
    </pui-date>

    <!-- ATTRIBUTE LINK -->
    <pui-common-select
      v-else-if="isSimpleLinkVisible"
      ref="attributeField"
      :taggable="isMultiSelect"
      :multiple="isMultiSelect"
      :display="optionDisplay"
      :disabled="isDisabled"
      :options="options"
      :isLoading="isLoadingOptions"
      :value="linkValue"
      :showLabel="showLabel"
      :label="label"
      :placeholder="placeholder"
      :max="maxSelected"
      :showNoOptions="showNoOptions"
      :optionsLimit="optionsLimit"
      :showOptionsFilter="showOptionsFilter"
      :allowGrouping="allowGrouping"
      :allowParentSelection="allowParentSelection"
      :tabindex="tabindex"
      class="pui-attribute-panel-field pui-attribute-panel__select"
      @remove="onSelectRemove"
      @select="onSelectAdd"
      @listUpdated="listUpdated"
      @selectionCleared="listUpdated"
      @blur="onBlurList"
      @open="onOpenList"
      @close="onCloseList"
      @focus="$emit('focus', $event)"
    >
      <template #default>
        <pui-input-message
          v-if="activateValidationMessages"
          :visible="isRequired"
          :message="$t('common.attribute_panel.required')"
        />
        <pui-input-message
          v-if="activateValidationMessages"
          :visible="errorMaxLink"
          :message="maxElementsMessage || $t('common.attribute_panel.max', { max })"
        />
      </template>

      <template #noOptions>
        <no-options
          :isLoadError="hasOptionsError"
          :noOptionsMessage="noOptionsMessage"
          :loadOptions="loadOptions"
        ></no-options>
      </template>

      <template #maxElements>
        <span v-if="maxElementsMessage">{{ maxElementsMessage }}</span>
      </template>
    </pui-common-select>

    <!-- ATTRIBUTE LINK -->
    <pui-attribute-input-tag
      v-else-if="isExtendedLinkVisible"
      ref="attributeField"
      :multiple="isMultiSelect"
      :display="optionDisplay"
      :disabled="isDisabled"
      :options="options"
      :isLoading="isLoadingOptions"
      :value="linkValue"
      :showLabel="showLabel"
      :label="label"
      :placeholder="placeholder"
      :max="maxSelected"
      :optionsLimit="optionsLimit"
      :showNoOptions="showNoOptions"
      :noOptionsMessage="noOptionsMessage"
      :hideTagsBlock="hasOptionsError"
      :lineCount="lineCount"
      :tabindex="tabindex"
      class="pui-attribute-panel-field pui-attribute-panel__input-tag-extended"
      @remove="onSelectRemove"
      @select="onSelectAdd"
      @listUpdated="listUpdated"
      @selectionCleared="listUpdated"
      @blur="onBlurList"
      @focus="$emit('focus', $event)"
    >
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="isRequired"
        :message="$t('common.attribute_panel.required')"
      />

      <!-- Only render this slot if we have an error, so that -->
      <!-- the default placeholder can be shown otherwise -->
      <template v-if="hasOptionsError" #placeholder>
        <pui-flex
          v-if="hasOptionsError"
          class="options-error__container"
          justifyContent="center"
          alignItems="center"
          flex="1"
        >
          <i class="mdi mdi-alert options-error__icon"></i>
          <pui-flex direction="column" class="options-error__message container">
            <span class="options-error__message primary">
              {{ $t('common.attribute_panel.error.load_options.primary') }}
            </span>
            <span class="options-error__message secondary" @click.stop="loadOptions">
              {{ $t('common.attribute_panel.error.load_options.secondary') }}
            </span>
          </pui-flex>
        </pui-flex>
      </template>
    </pui-attribute-input-tag>

    <!-- ATTRIBUTE LINK TABLE -->
    <pui-attribute-table
      v-else-if="isTableLink"
      ref="attributeField"
      :attribute="attribute"
      :values="value"
      :allowExtendedDisplayMode="allowExtendedDisplayMode"
      :tabindex="tabindex"
      :disabled="disabled"
      :activateValidationMessages="activateValidationMessages"
      :lazyOptions="lazyOptions"
      :loadOptionsFunction="loadOptionsFunction"
      :importExternalFiles="importExternalFiles"
      class="pui-attribute-panel-field pui-attribute-panel__table"
      isCellSelectable
      @valueInput="onInput($event)"
      @valueChanged="setValue($event)"
      @searchRequested="$emit('searchRequested', $event)"
    />

    <!-- ATTRIBUTE LINK TREE -->
    <pui-attribute-tree
      v-else-if="isTreeLink"
      ref="attributeField"
      :attribute="attribute"
      :disabled="isDisabled"
      :showLabel="showLabel"
      :selection="value"
      :isLoadingRoots="isLoadingOptions"
      :loadRootsFunction="_loadOptions"
      class="pui-attribute-panel-field pui-attribute-panel__tree"
      @checkChange="onTreeCheckChange"
    >
    </pui-attribute-tree>

    <!-- ATTRIBUTE BOOLEAN -->
    <pui-checkbox
      v-else-if="isBooleanVisible"
      ref="attributeField"
      :showLabel="showLabel"
      :label="label"
      :required="isRequired"
      :disabled="isDisabled"
      :checked="value"
      :tabindex="tabindex"
      class="pui-attribute-panel-field pui-attribute-panel__checkbox"
      @change="setValue($event)"
      @blur="onBlur"
      @focus="$emit('focus', $event)"
    >
      <pui-input-message
        v-if="activateValidationMessages"
        :visible="isRequired"
        :message="$t('common.attribute_panel.required')"
      />
    </pui-checkbox>

    <pui-attribute-external-link
      v-else-if="isExternalLinkVisible"
      ref="attributeField"
      :attribute="attribute"
      :activateValidationMessages="activateValidationMessages"
      :showLabel="showLabel"
      :importFiles="importExternalFiles"
      :files="value"
      :extendedMode="allowExtendedDisplayMode"
      :disabled="isDisabled"
      v-bind="externalLinkProps"
      class="pui-attribute-panel-field pui-attribute-panel__attribute_external_link"
      @change="setValue($event)"
    >
    </pui-attribute-external-link>
  </div>
</template>

<script>
import { getFormattingManager } from 'piivo-poster-engine/src/lib/services/formattingManager';
import Vue from 'vue';

import PuiAttributeTable from '@/modules/common/components/attributes/AttributeTable';

import { isoDate, isoDateFull } from '../../../../core/filters';
import services from '../../../../core/services';
import { ensureArray } from '../../../../utils/array';
import { trimWithMode } from '../../../common/utils/attributes';
import { AttributeTypes } from '../../constants';
import {
  validateBoolean,
  validateFormValue,
  validateMaxItems,
  validateMaxNumeric,
  validateMinItems,
  validateMinNumeric,
  validateRequiredDate,
  validateRequiredMultiString,
  validateRequiredNumeric,
  validateRequiredString,
  validateStringMaxLength,
  validateStringMinLength,
} from '../../helpers/attributeValidators';
import { identifiersToLinkOptions, parseNumericValue } from '../../helpers/formsHelper';
import puiAttributeInputTag from './AttributeInputTag';
import noOptions from './NoOptions.vue';

export default {
  name: 'PuiAttributePanel',
  components: {
    PuiAttributeTable,
    puiAttributeInputTag,
    noOptions,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    default: {
      type: String,
    },
    attribute: {
      required: true,
    },
    value: {
      required: true,
    },
    /**
     * Force associated label to show/hide (default null)
     */
    displayLabel: {
      type: Boolean,
      default: null,
    },
    activateSearch: {
      type: Boolean,
      default: true,
    },
    activateValidationMessages: {
      type: Boolean,
      default: true,
    },
    /**
     * Load function for paginated Links attribute (async loading) options.
     *
     * @type {(attribute: object, options: { context?: object }) => Promise<object[]|null>}
     */
    loadOptionsFunction: {
      type: Function,
      default: null,
    },
    /**
     * Parameters to send to loadOptionsFunction
     */
    loadOptionsParameters: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Will only trigger a load of the options on the
     * first open of the select. This will only be applied
     * if the value is empty on mount. Otherwise the load will
     * be invoked on mount.
     * Also only applies for simple select links (not AttributeInpputTag)
     */
    lazyOptions: {
      type: Boolean,
      default: false,
    },
    /**
     * If this is a link indicates that this attribute can show
     * its extended display mode
     */
    allowExtendedDisplayMode: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: [String, Number],
      default: null,
    },
    /**
     * Import function for external link.
     * Receives an array of objects in which to iterate to upload, returns all the imported files
     *
     * @type {(
     * attribute: Attribute,
     * tempFiles: F[],
     * onFileUploadedCb: (tempFile: F, f: any) => void,
     * provideCancelCb: (cancelCb: (tempId?: string) => void) => void
     * ) => Promise<object[]>}
     **/
    importExternalFiles: {
      type: Function,
      default: null,
    },
    /**
     *Function to display the label
     */
    labelFunction: {
      type: Function,
      default: null,
    },
    /**
     * Extra props for an external link
     */
    externalLinkProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      AttributeTypes: AttributeTypes,
      options: [],
      isLoadingOptions: false,
      hasOptionsError: false,
      loadOptionsPromise: null,
    };
  },
  computed: {
    classObject() {
      return ['pui-attribute-panel'];
    },
    /**
     * Show associated label
     * Default true, false if option is defined to false (can be forced by displayLabel prop)
     */
    showLabel() {
      return this.displayLabel != null
        ? this.displayLabel !== false // Forced prop value
        : !this.attribute.options || this.attribute.options.showLabel !== false; // Attribute option value
    },
    /**
     * @returns {string} label for the form
     */
    label() {
      if (this.labelFunction) {
        return this.labelFunction(this.attribute);
      }
      return this.piivoTranslate(this.attribute);
    },
    /**
     * @returns {object[]} the value as option objects
     */
    linkValue() {
      return identifiersToLinkOptions(this.value, this.options);
    },
    isDisabled() {
      return this.disabled || (this.attribute.options && this.attribute.options.enabled === false);
    },
    isRequired() {
      return (
        this.attribute.options &&
        this.attribute.options.required === true &&
        (this.value == null || this.value === '' || this.value.length === 0)
      );
    },
    isVisible() {
      return (
        (!this.attribute.options || this.attribute.options.visible !== false) &&
        (this.attribute.permission === '' ||
          this.attribute.permission == null ||
          services.getService('auth').hasPermission(this.attribute.permission)) &&
        (!this.attribute.options || this.attribute.options.visibleCondition !== false)
      );
    },
    getName() {
      return "('att'+attribute.itemId)";
    },
    inputValue() {
      if (this.isDateVisible) {
        return isoDate(this.value);
      } else if (this.isDateTimeVisible) {
        return isoDateFull(this.value);
      }
      return this.value;
    },
    min() {
      if (!this.attribute.options) {
        return Number.MIN_VALUE;
      }
      return this.attribute.options.minimum;
    },
    max() {
      if (!this.attribute.options) {
        return Number.MAX_VALUE;
      }
      return this.attribute.options.maximum;
    },
    step() {
      return this.attribute.options.step;
    },
    maxLength() {
      if (!this.attribute.options) {
        return Number.MAX_VALUE;
      }

      const val = this.attribute.options.maximumLength;
      return val === 0 ? null : val;
    },
    /**
     * @returns {boolean} if the max length counter should be shown
     */
    showCharacterCounter() {
      return this.attribute.options ? this.attribute.options.showCharacterCounter : false;
    },
    minLength() {
      if (!this.attribute.options) {
        return 0;
      }
      return this.attribute.options.minimumLength;
    },
    /**
     * Maximum items is the max for input multiple value
     * */
    maximumItems() {
      if (!this.attribute.options) {
        return Number.MAX_VALUE;
      }
      return this.attribute.options.maximumItems &&
        this.attribute.options.maximumItems !== null &&
        this.attribute.options.maximumItems !== 0
        ? this.attribute.options.maximumItems
        : Number.MAX_VALUE;
    },
    /**
     * Minimum items is the min for input multiple value
     * */
    minimumItems() {
      if (!this.attribute.options) {
        return 0;
      }
      return this.attribute.options.minimumItems ? this.attribute.options.mininimumItems : 0;
    },
    rows() {
      if (this.attribute.type !== AttributeTypes.LINKS) {
        return this.attribute.options.rowCount;
      } else {
        return this.attribute.options && this.attribute.options.displayOptions
          ? this.attribute.options.displayOptions.rowCount
          : null;
      }
    },
    /**
     * Item label display (name of items)
     */
    itemLabel() {
      return this.attribute.options ? this.attribute.options.itemLabel : '';
    },
    /**
     * Flag indicating if attribute has search.
     */
    hasSearch() {
      return this.activateSearch && this.attribute.search != null;
    },
    /**
     * Search link picto.
     */
    searchLinkPicto() {
      return this.attribute.options &&
        this.attribute.options.searchLink &&
        this.attribute.options.searchLink.picto
        ? this.attribute.options.searchLink.picto
        : 'mdi-magnify';
    },
    /**
     * Multiple select.
     */
    isMultiSelect() {
      return this.attribute.options && this.attribute.options.multiSelect === true;
    },
    /**
     * Placeholder
     */
    placeholder() {
      if (this.attribute.type !== AttributeTypes.LINKS) {
        return this.attribute.options && this.attribute.options.placeholder
          ? this.attribute.options.placeholder
          : null;
      } else {
        return this.attribute.options &&
          this.attribute.options.displayOptions &&
          this.attribute.options.displayOptions.placeholder
          ? this.attribute.options.displayOptions.placeholder
          : null;
      }
    },
    /**
     * @returns {string} type of resize to allow for textarea
     */
    resize() {
      return this.attribute.options && this.attribute.options.resize
        ? this.attribute.options.resize
        : null;
    },
    /**
     * Max selected elements
     */
    maxSelected() {
      return this.attribute.options &&
        this.attribute.options.maximum !== null &&
        this.attribute.options.maximum !== 0
        ? this.attribute.options.maximum
        : false;
    },
    /**
     * Message if max elements selected
     */
    maxElementsMessage() {
      return this.attribute.options ? this.attribute.options.maximumMessage : null;
    },
    /**
     * Message if no elements in list (select component)
     */
    noOptionsMessage() {
      return this.attribute.options ? this.attribute.options.noOptionsMessage : null;
    },
    /**
     * Show no options message.
     */
    showNoOptions() {
      return !this.attribute.options || this.attribute.options.showNoOptions !== false;
    },
    /**
     * Limits the number of elements displayed.
     */
    optionsLimit() {
      return this.attribute.options && this.attribute.options.optionsLimit != null
        ? this.attribute.options.optionsLimit
        : null;
    },
    /**
     * Flag indicating if attribute is multiple (for string attribute input or inputTag).
     * */
    multiple() {
      return this.attribute.options && this.attribute.options.multiple === true;
    },
    clearable() {
      return this.attribute.options && this.attribute.options.clearable
        ? this.attribute.options.clearable
        : true;
    },
    /**
     * @returns {Boolean} if this attribute is a string input
     */
    isSingleStringVisible() {
      return (
        [AttributeTypes.STRING, AttributeTypes.LABEL].includes(this.attribute.type) &&
        !this.multiple
      );
    },
    // SINGLE STRING VALIDATION
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a required string condition
     */
    errorRequiredString() {
      return !validateRequiredString(this.inputValue);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a minimum number of characters
     */
    errorMinChars() {
      return !validateStringMinLength(this.inputValue, this.minLength);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a maximum number of characters
     */
    errorMaxChars() {
      return !validateStringMaxLength(this.inputValue, this.maxLength);
    },
    /**
     * @returns {Boolean} if this attribute is a multi string select
     */
    isMultiStringVisible() {
      return AttributeTypes.STRING === this.attribute.type && this.multiple;
    },
    /**
     * @returns {Boolean} if this attribute is a simple multi string select
     */
    isSimpleMultiString() {
      return (
        this.isMultiStringVisible &&
        // No displayMode corresponds to simple mode for backwards compatibility
        (!this.attribute.options.displayMode || this.attribute.options.displayMode === 'Summary')
      );
    },
    // MULTI STRING VALIDATION
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a required array condition
     */
    errorRequiredMultiString() {
      return !validateRequiredMultiString(this.inputValue);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a minimum number of strings
     */
    errorMinStrings() {
      return !validateMinItems(this.inputValue, this.minimumItems);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a maximum number of strings
     */
    errorMaxStrings() {
      return !validateMaxItems(this.inputValue, this.maximumItems);
    },
    // NUMERIC
    /**
     * @returns {Boolean} if this attribute is a number input
     */
    isNumericVisible() {
      return this.attribute.type === AttributeTypes.NUMERIC;
    },
    // NUMERIC VALIDATION
    /**
     * @returns {(Number|NaN)} the parsed number of the input
     */
    parsedNumeric() {
      return parseNumericValue(this.inputValue);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a required number condition
     */
    errorRequiredNumeric() {
      return !validateRequiredNumeric(this.inputValue);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a minimum number
     */
    errorMinNumeric() {
      return !validateMinNumeric(this.inputValue, this.min);
    },
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a maximum number
     */
    errorMaxNumeric() {
      return !validateMaxNumeric(this.inputValue, this.max);
    },
    // DATE
    /**
     * @returns {Boolean} if this attribute is a date input
     */
    isDateVisible() {
      return (
        this.attribute.type === AttributeTypes.DATE &&
        (!this.attribute.options || !this.attribute.options.showTime)
      );
    },
    /**
     * @returns {Boolean} if this attribute is a datetime input
     */
    isDateTimeVisible() {
      return (
        this.attribute.type === AttributeTypes.DATE &&
        this.attribute.options &&
        this.attribute.options.showTime
      );
    },
    // DATE VALIDATION
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a required date condition
     */
    errorRequiredDate() {
      return !validateRequiredDate(this.inputValue);
    },
    // LINK
    /**
     * @returns {Boolean} if this attribute is a string link
     */
    isComboLinkExtendedDisplayMode() {
      return (
        this.attribute.options.displayOptions.enableExtendedView && this.allowExtendedDisplayMode
      );
    },
    isTableLink() {
      return (
        this.attribute.type === AttributeTypes.LINKS &&
        this.attribute.options.displayMode === 'Table'
      );
    },
    /**
     * @returns {boolean} if this attribute is a combo link
     */
    isComboLink() {
      return (
        this.attribute.type === AttributeTypes.LINKS &&
        this.attribute.options.displayMode === 'Combo'
      );
    },
    /**
     * @returns {boolean} if this attribute is a combo link
     */
    isTreeLink() {
      return (
        this.attribute.type === AttributeTypes.LINKS &&
        this.attribute.options.displayMode === 'Tree'
      );
    },
    /**
     * @returns {Boolean} if this attribute is a simple
     * combo string link
     */
    isSimpleLinkVisible() {
      return this.isComboLink && !this.isComboLinkExtendedDisplayMode;
    },
    /**
     * @returns {Boolean} if this attribute is an extended combo
     * string link
     */
    isExtendedLinkVisible() {
      return this.isComboLink && this.isComboLinkExtendedDisplayMode;
    },
    /**
     * @returns {number} the number of lines to show in the extended
     * input tag
     */
    lineCount() {
      return this.attribute.options && this.attribute.options.lineCount != null
        ? this.attribute.options.lineCount
        : null;
    },
    /**
     * @returns {boolean} if the options filter should be displayed
     */
    showOptionsFilter() {
      return this.attribute.options && this.attribute.options.displayOptions
        ? this.attribute.options.displayOptions.showOptionsFilter
        : false;
    },
    /**
     * @returns {boolean} if the options can be displayed as groups
     */
    allowGrouping() {
      return this.attribute.options && this.attribute.options.displayOptions
        ? this.attribute.options.displayOptions.allowGrouping
        : false;
    },
    /**
     * @returns {boolean} if parent options can be selected. Only if allowGrouping is enabled.
     */
    allowParentSelection() {
      return this.attribute.options && this.attribute.options.displayOptions
        ? this.attribute.options.displayOptions.allowParentSelection
        : false;
    },
    // LINK VALIDATION
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a required string condition
     */
    errorRequiredLink() {
      return !validateRequiredString(this.inputValue);
    },
    /**
     * @returns {Boolean} if the current link value does not satisfy
     * a maximum number
     */
    errorMaxLink() {
      return (
        this.maxSelected !== false && !validateMaxNumeric(this.linkValue.length, this.maxSelected)
      );
    },
    // EXTERNAL LINK
    isExternalLinkVisible() {
      return this.attribute.type === AttributeTypes.EXTERNAL_LINK;
    },
    // BOOLEAN
    /**
     * @returns {Boolean} if this attribute is a checkbox input
     */
    isBooleanVisible() {
      return this.attribute.type === AttributeTypes.BOOLEAN;
    },
    // BOOLEAN VALIDATION
    /**
     * @returns {Boolean} if the current value does not satisfy
     * a required boolean condition
     */
    errorRequiredBoolean() {
      return !validateBoolean(this.inputValue);
    },
  },
  watch: {
    /**
     * Watches 'isSimpleLinkVisible' to load the options if ncessary
     *
     * @param {boolean} nextValue - the next value
     */
    isSimpleLinkVisible(nextValue) {
      if (this.shouldLoadOptionsForSimpleLink(nextValue)) {
        this.loadOptions();
      }
    },
    /**
     * Watches 'isExtendedLinkVisible' to load the options if necessary
     *
     * @param {boolean} nextValue - the next value
     */
    isExtendedLinkVisible(nextValue) {
      if (this.shouldLoadOptionsForExtendedLink(nextValue)) {
        this.loadOptions();
      }
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    /**
     * @param {object} option - option object
     * @returns {string} the option label
     */
    optionDisplay(option) {
      if (Object.hasOwnProperty.call(option, '_labelKey')) {
        return this.$t(option._labelKey);
      }
      return this.piivoTranslate(option);
    },
    /**
     * Blurs the rendered form component
     */
    blur() {
      if (this.$refs.attributeField && this.$refs.attributeField.blur) {
        this.$refs.attributeField.blur();
      }
    },
    focus() {
      if (this.$refs.attributeField && this.$refs.attributeField.focus) {
        this.$refs.attributeField.focus();
      }
    },
    getInput() {
      return this.$refs.attributeField;
    },
    onSelectRemove(item) {
      this.$emit('remove', item);
    },
    onSelectAdd(item) {
      this.$emit('select', item);
    },
    /**
     *
     * @param {*} values - the new values
     * @param {boolean} [idsChanged=true] - if the actual identifiers of the options changed
     */
    listUpdated(values, idsChanged = true) {
      const normalizedObjects = this.getNormalizedValueObjects(values);
      if (
        this.isSimpleLinkVisible &&
        this.isMultiSelect &&
        this.attribute.options.displayOptions.sortSelection
      ) {
        normalizedObjects.sort((a, b) => a.priority - b.priority);
      }

      // If the ids did change, emit as a change. Otherwise
      // emit as input so parent can detect the change but know
      // that the real value did not change
      if (idsChanged) {
        this.setValue(normalizedObjects);
      } else {
        this.onInput(normalizedObjects);
      }
    },
    /**
     * Triggered on item removal
     *
     * @param {*} item - the item to remove
     * @param {number} itemIndex - the index of the item to remove
     */
    onRemoveItem(item, itemIndex) {
      const newValue = [...this.getNormalizedValueObjects(this.inputValue)];
      newValue.splice(itemIndex, 1);
      this.setValue(newValue);
    },
    /**
     * Trims the value if necessary
     *
     * @param {*} value the value
     * @returns {string} the trimmed value
     */
    trimValue(value) {
      // Trim value with trim option
      if (
        this.attribute.type === AttributeTypes.LABEL ||
        (this.attribute.type === AttributeTypes.STRING && !this.multiple)
      ) {
        return trimWithMode(value, this.attribute.options.trim);
      }

      return value;
    },
    /**
     * Handles change event and reemits
     *
     * @param {*} newValue - the new value
     */
    setValue(newValue) {
      const trimmed = this.trimValue(newValue);

      this.$emit('valueChanged', trimmed);
    },
    /**
     * Handles input event and reemits
     *
     * @param {*} newValue - the new value
     */
    onInput(newValue) {
      const trimmed = this.trimValue(newValue);

      this.$emit('valueInput', trimmed);
    },
    /**
     * Handles change of a tree link
     *
     * @param {object} object map of checked node state
     * @param {object[]} array of checked nodes
     */
    onTreeCheckChange(checkedNodeIds, selection) {
      this.setValue(selection);
    },
    /**
     * @param {object} attribute - the attribute with
     * the search definition
     */
    onSearch() {
      if (!this.activateSearch) return;
      this.$emit('searchRequested', { attribute: this.attribute });
    },
    onKeyUp(event) {
      this.$emit('keyup', event, this.attribute.itemId, this.inputValue);
    },
    onBlur(value) {
      this.$emit('blur', value);
    },
    onBlurList(values) {
      this.$emit('blur', this.getNormalizedValues(values));
    },
    /**
     * When the Select is opened, will load the options
     */
    onOpenList(ev) {
      this.$emit('focus', ev);
      if (this.lazyOptions && !this.options.length) {
        this.loadOptions();
      }
    },
    /**
     * When the select is now closed
     */
    onCloseList() {
      this.$emit('onCloseList');
    },
    numberFormatter(value) {
      if (this.attribute.options && this.attribute.options.format) {
        return getFormattingManager().getFormatter('Numeric')(value, this.attribute.options.format);
      }

      return value;
    },
    getNormalizedValues(values) {
      if (!(values instanceof Array)) {
        values = [values];
      }
      const normalizedValues = [];
      values.forEach((value) => {
        if (value != null) {
          normalizedValues.push(value.itemId);
        }
      });
      return normalizedValues;
    },
    getNormalizedValueObjects(values) {
      if (!(values instanceof Array)) {
        values = [values];
      }
      const normalizedValues = [];
      values.forEach((value) => {
        if (value != null) {
          normalizedValues.push(value);
        }
      });
      return normalizedValues;
    },
    /**
     * Hook to load the options of the attribute (for Links attribute).
     *
     * @returns {Promise}
     */
    async loadOptions() {
      if (this.loadOptionsPromise) {
        // Return the current load: do not launch 2 loads concurrently.
        // We are assuming that the concurrent calls were unnecessary.
        return this.loadOptionsPromise;
      }

      // For a table attribute, delegate loadOptions to the AttributePanels
      // inside the table
      if (this.isTableLink) {
        if (this.$refs.attributeField) {
          this.loadOptionsPromise = this.$refs.attributeField.loadOptions();
          return this.loadOptionsPromise;
        }
        // Return "empty" promise since there is nothing to do
        return Promise.resolve();
      } else if (this.isTreeLink) {
        // For tree attribute, delegate loadOptions to the tree
        if (this.$refs.attributeField) {
          this.loadOptionsPromise = this.$refs.attributeField.loadRoots();
          return this.loadOptionsPromise;
        }

        return Promise.resolve();
      } else if (this.isComboLink) {
        // Only trigger a load for dynamic option attributes
        this.loadOptionsPromise = this._loadOptions();
        return this.loadOptionsPromise;
      }
    },
    /**
     * Load the options of the attribute (for Links attribute).
     *
     * @returns {Promise}
     */
    async _loadOptions() {
      // Load options from prop function if exists
      if (this.loadOptionsFunction != null) {
        this.hasOptionsError = false;
        this.isLoadingOptions = true;

        try {
          this.options = [];

          const results = await this.loadOptionsFunction(this.attribute, {
            ...this.loadOptionsParameters,
          });
          this.options = results || [];

          // If the computed linkValue length is different from the prop value length
          // that means that before the load, some values were not found in our options.
          // Emit the current value as the new value as a change event
          const currentValue = ensureArray(this.value);
          if (this.linkValue.length !== currentValue.length) {
            this.listUpdated(this.linkValue, true);
          } else if (
            this.linkValue.some((linkEl, idx) => typeof linkEl !== typeof currentValue[idx])
          ) {
            // Else if the computed linkValue length is the same but does not contain the same object types
            // that means that we originally had id values but now have full object values.
            // Emit the current value but as an input event
            this.listUpdated(this.linkValue, false);
          }
        } catch (err) {
          this.hasOptionsError = true;
          this.options = [];

          services
            .getService('alerts')
            .alertError(this.$t('common.attribute_panel.error.load_options.alert_error'));
        }

        this.isLoadingOptions = false;
        this.loadOptionsPromise = null;

        return this.options;
      }
    },
    /**
     * @returns {Boolean} if the value does not pass the attribute error validation
     */
    hasError() {
      return (
        Object.keys(validateFormValue(this.inputValue, this.attribute, true).errors).length > 0
      );
    },
    /**
     * Checks if the simple link requires options to be loaded
     * @param {boolean} isSimpleLinkVisible - the new value
     * @returns {boolean} if the options should be loaded
     */
    shouldLoadOptionsForSimpleLink(isSimpleLinkVisible) {
      // If the link suddenly becomes visible: only load the options
      // if we have a value (we need the options to display the value object)
      // or we are NOT lazy loading the options
      return isSimpleLinkVisible && (!!this.value || !this.lazyOptions);
    },
    /**
     * Checks if the extended link requires options to be loaded
     * @param {boolean} isSimpleLinkVisible - the new value
     * @returns {boolean} if the options should be loaded
     */
    shouldLoadOptionsForExtendedLink(isExtendedLinkVisible) {
      // If the link suddenly becomes extended, we have to load the options
      // because there is no way for the user to trigger a load manually
      return isExtendedLinkVisible && !this.options.length;
    },
  },
  /**
   * Mounted hook.
   */
  mounted() {
    // Load the options if necessary
    // Note: don't check for Links>Table type, that is handled by AttributeTable
    if (
      this.shouldLoadOptionsForSimpleLink(this.isSimpleLinkVisible) ||
      this.shouldLoadOptionsForExtendedLink(this.isExtendedLinkVisible) ||
      this.isTableLink ||
      this.isTreeLink
    ) {
      this.loadOptions();
    }
  },
};
</script>
