import { TableRenderers } from 'piivo-ui/src/components/table/renderers';

import BooleanAttributeRenderer from './BooleanAttributeRenderer';
import StringAttributeRenderer from './StringAttributeRenderer';

export class CommonTableRenderers extends TableRenderers {
  constructor() {
    super();

    this.registerRenderer('String', StringAttributeRenderer);
    this.registerRenderer('Boolean', BooleanAttributeRenderer);
  }
}
