import { PersistKeys } from '../plugins/persistence';

export const SET_VERSIONS = 'SET_VERSIONS';

/**
 * Module namespace
 */
export const NAMESPACE = 'versions';

/**
 * Versions store module.
 */
export const VersionsModule = {
  namespaced: true,
  state: {
    [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
    localVersion: null,
    version: null,
    configurationVersion: null,
  },

  getters: {
    /**
     * @param {*} state - local state
     * @returns the versions object
     */
    versions: (state) => ({
      localVersion: state.localVersion,
      version: state.version,
      configurationVersion: state.configurationVersion,
    }),
  },

  actions: {},

  mutations: {
    /**
     * Updates the version
     *
     * @param {Object} state - Store state
     * @param {object} versions - the versions
     */
    [SET_VERSIONS](state, versions) {
      state.localVersion = versions.localVersion;
      state.version = versions.version;
      state.configurationVersion = versions.configurationVersion;
    },
  },
};
