<template>
  <div :title="formatData(value)" class="renderer-string-content">{{ formatData(value) }}</div>
</template>
<script>
import TableAttributeRendererMixin from './TableAttributeRendererMixin';

export default {
  mixins: [TableAttributeRendererMixin],
  methods: {
    /**
     * Format renderer value.
     * @param {Object} value : The value to format.
     */
    formatData(value) {
      if (!this.formatter) {
        return value;
      }
      return this.formatter.value(value, this.formatter.pattern);
    },
  },
};
</script>
