import { sortFns } from './sorting';
import { SortingManager } from './sortingManager';

export function registerExtensionPoint(path, extensionPoint) {
  if (window && !window.Piivo) {
    window.Piivo = {};
  }
  if (window) {
    window.Piivo[path] = extensionPoint;
  }
}

export function getExtensionPoint(path) {
  if (window && !window.Piivo) {
    window.Piivo = {};
  }
  if (window) {
    return window.Piivo[path];
  } else {
    return null;
  }
}

const sortingManager = new SortingManager();
sortFns.forEach((sortFn) => {
  sortingManager.registerSortFn(sortFn.name, sortFn.func);
});

registerExtensionPoint('core', {
  sortingManager,
});

export default {
  registerExtensionPoint,
  getExtensionPoint,
};
