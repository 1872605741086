import { Scopable } from '../types/scopable';

/**
 * @param scope - scope to filter on
 * @param arr - array of elements to filter
 * @returns the elements that are scoped to the filter, or aren't scoped at all
 */
export const filterByScope = <T extends Scopable>(
  scope: string | null | undefined,
  arr: T[]
): T[] => {
  if (scope === null || scope === undefined) {
    return arr;
  }
  return arr.filter((el) => !el.scopes || !el.scopes.length || el.scopes.includes(scope));
};
