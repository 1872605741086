<template>
  <div class="pui-attribute-table pui-attribute-table__root">
    <pui-common-spinner
      v-if="isImporting"
      :message="$t('common.attribute_table.isImporting')"
      position="fixed"
    ></pui-common-spinner>

    <pui-flex class="pui-attribute-table__top" direction="column">
      <pui-flex wrap="wrap">
        <pui-flex v-if="attribute.options.showLabel" class="table-label" alignItems="center">
          {{ attribute.label | piivoTranslateLabel }}
        </pui-flex>

        <pui-flex class="pui-attribute-table__config-right" alignItems="center">
          <a
            v-if="!disabled && activateSearch && attribute.search"
            class="action-btn search-add-btn"
            @click="onSearchClicked"
          >
            {{ $t('common.attribute_table.search_add') }}
          </a>
          <template v-if="!disabled && !!attribute.options.valueImportConfiguration">
            <a class="action-btn import-btn" @click="onClickImport">{{
              $t('common.attribute_table.import')
            }}</a>
            <input v-show="false" ref="importFile" type="file" @change="onImportFile" />
          </template>

          <pui-menu-button v-if="!disabled">
            <template #default="{ closePopper }">
              <pui-button
                block
                flat
                @click="
                  closePopper();
                  requestClearTable();
                "
              >
                {{ $t('common.attribute_table.clear') }}
              </pui-button>
            </template>
          </pui-menu-button>
        </pui-flex>
      </pui-flex>

      <pui-flex
        v-if="displayError && activateValidationMessages"
        class="pui-attribute-table__error-message"
      >
        <i class="error-icon mdi mdi-alert"></i>
        <span class="msg">{{ errorMessage }}</span>
      </pui-flex>
    </pui-flex>
    <div
      ref="table"
      :style="{ '--drag-handle-width': DRAG_HANDLE_WIDTH }"
      class="pui-attribute-table__table"
    >
      <div
        ref="tableHeader"
        :style="`width: max(${tableOptions.rowWidth}, 100%)`"
        class="pui-attribute-table__row header"
      >
        <pui-table-header
          v-if="tableOptions.showOrder"
          :sortable="false"
          :visible="true"
          :class="{
            'pui-attribute-table__cell header': true,
            'sticky-cell': tableOptions.stickyFirstColumn,
          }"
          :style="{
            '--sticky-z-index': `${tableOptions.stickyFirstColumn ? 115 : null}`,
            '--sticky-offset': '0px',
          }"
          :width="DRAG_HANDLE_WIDTH"
        />
        <pui-table-header
          v-for="(column, columnIndex) in columns"
          :key="columnIndex"
          :label="column.name"
          :value="column.value"
          :variant="column.class"
          :width="column.width"
          :minimizedWidth="column.minimizedWidth"
          :sortable="false"
          :alignment="column.alignment"
          :visible="column.visible"
          :headerTranslator="column.headerTranslator"
          :class="{
            'pui-attribute-table__cell header': true,
            'sticky-cell':
              tableOptions.stickyFirstColumn && columnIndex === firstVisibleColumnIndex,
          }"
          :style="{
            '--sticky-z-index': `${tableOptions.stickyFirstColumn ? 115 : null}`,
            '--sticky-offset': tableOptions.showOrder ? DRAG_HANDLE_WIDTH : '0px',
          }"
        />
        <div class="row-menu-button-spacer"></div>
      </div>

      <transition-group tag="div" class="row-container" name="row-transition-group">
        <pui-table-row
          v-for="(value, valueIndex) in allValues"
          :key="value._id"
          :ref="`row_${valueIndex}`"
          :item="value"
          :enableValidation="false"
          :style="`width: max(${tableOptions.rowWidth}, 100%)`"
          :draggable="tableOptions.showOrder && dnd.row === valueIndex"
          :class="{
            'pui-attribute-table__row body': true,
            'row-draggable': dnd.row === valueIndex,
            draggable: dnd.row === valueIndex,
          }"
          @dragenter="onDragEnter"
          @dragover="onDragOver($event, valueIndex)"
          @drop="onDrop"
          @dragstart="onDragStart($event, valueIndex)"
          @dragend="onDragEnd"
          @click="onDraggableElementClick(valueIndex)"
        >
          <pui-flex
            v-if="tableOptions.showOrder"
            :class="{
              'drag-handle-cell': true,
              clickable: valueIndex < internalValues.length,
              draggable: dnd.row === valueIndex,
              'sticky-cell': tableOptions.stickyFirstColumn,
              faded: valueIndex === extraLineIndex,
            }"
            :style="{
              '--sticky-z-index': `${
                tableOptions.stickyFirstColumn ? (valueIndex === focusedRowIndex ? 115 : 110) : null
              }`,
              '--sticky-offset': '0px',
            }"
            justifyContent="center"
            alignItems="center"
            @click="onDraggableElementClick(valueIndex)"
          >
            {{ valueIndex + 1 }}
          </pui-flex>

          <pui-table-cell
            v-for="(column, columnIndex) in columns"
            :key="columnIndex"
            :variant="column.class"
            :width="column.width"
            :minimizedWidth="column.minimizedWidth"
            :alignment="column.alignment"
            :visible="column.visible"
            :validator="column.validator"
            :class="cellClass(columnIndex, valueIndex)"
            :style="{
              '--sticky-z-index': `${
                tableOptions.stickyFirstColumn ? (valueIndex === focusedRowIndex ? 115 : 110) : null
              }`,
              '--sticky-offset': tableOptions.showOrder ? DRAG_HANDLE_WIDTH : '0px',
            }"
            @click="
              setCurrentCell(columnIndex, valueIndex, $event);
              onDraggableElementClick(valueIndex);
            "
          >
            <!-- Render empty span so TableCell has something in it's slot,
        to prevent default behaviour -->
            <span v-if="!column.attribute"></span>
            <pui-attribute-panel
              v-else
              :ref="getRowCellName(columnIndex, valueIndex)"
              :key="column.itemId"
              :attribute="column.attribute"
              :value="value[columnIndex]"
              :loadOptionsFunction="loadOptionsFunction"
              :loadOptionsParameters="{ context: { lineIndex: valueIndex } }"
              :displayLabel="false"
              :allowExtendedDisplayMode="allowExtendedDisplayMode"
              :externalLinkProps="{ retractedColCount: 2, extendedColCount: 4, hideTopMenu: true }"
              :tabindex="tabindex"
              :disabled="disabled || !column.editable"
              :activateValidationMessages="
                activateValidationMessages && valueIndex !== extraLineIndex
              "
              :lazyOptions="lazyOptions"
              :importExternalFiles="importExternalFiles"
              @valueInput="valueInput(columnIndex, valueIndex, $event)"
              @valueChanged="valueChanged(columnIndex, valueIndex, $event)"
              @enter="onKeyEnter(columnIndex, valueIndex)"
              @searchRequested="$emit('searchRequested', $event)"
              @focus="onCellFocused(columnIndex, valueIndex)"
            />
          </pui-table-cell>
          <div
            :class="{
              'row-menu-col': true,
              sticky: tableOptions.stickyRowMenu,
              draggable: dnd.row === valueIndex,
            }"
            :style="{
              '--z-index': `${valueIndex === focusedRowIndex ? 110 : 105}`,
            }"
            @click="onDraggableElementClick(valueIndex)"
          >
            <pui-menu-button
              v-if="!disabled && valueIndex !== extraLineIndex"
              :popperListeners="{
                open: () => {
                  focusedRowIndex = valueIndex;
                },
              }"
              stopPropagation
            >
              <template #default="{ closePopper }">
                <pui-flex direction="column" flex="1">
                  <pui-button
                    block
                    flat
                    @click.stop="
                      closePopper();
                      removeLine(valueIndex);
                    "
                    >{{ $t('common.attribute_table.remove_line') }}</pui-button
                  >
                  <pui-button
                    block
                    flat
                    :disabled="reachedMaxLines"
                    @click.stop="
                      closePopper();
                      duplicateLine(valueIndex);
                    "
                    >{{ $t('common.attribute_table.duplicate_line') }}</pui-button
                  >
                </pui-flex>
              </template>
            </pui-menu-button>
          </div>
        </pui-table-row>
      </transition-group>

      <!-- Render indicators in another component so this component -->
      <!-- won't trigger useless updates -->
      <pui-attribute-table-indicators :dnd="dnd"></pui-attribute-table-indicators>
    </div>

    <pui-dialog
      ref="clearDialog"
      :title="
        $t('common.attribute_table.clear_table_confirmation.title', {
          attribute: piivoTranslate(attribute),
        })
      "
      :showCloseButton="false"
      transition="slide-up"
      class="clear-table-dialog clear-table-dialog__root"
      @outsideClick="closeClearTableDialog"
    >
      <template #dialog-action>
        <div class="dialog-action">
          <pui-button
            class="clear-table-dialog__btn-cancel"
            variant="secondary"
            outline
            @click="closeClearTableDialog"
          >
            {{ $t('common.attribute_table.clear_table_confirmation.cancel') }}
          </pui-button>
          <pui-button
            class="clear-table-dialog__btn-create"
            variant="primary"
            @click="confirmClearTable"
          >
            {{ $t('common.attribute_table.clear_table_confirmation.clear') }}
          </pui-button>
        </div>
      </template>

      <template #default>
        <i18n
          path="common.attribute_table.clear_table_confirmation.message"
          class="message"
          tag="div"
        >
          <template #message_bold>
            <i18n
              path="common.attribute_table.clear_table_confirmation.message_bold"
              class="message-bold"
            ></i18n>
          </template>
          <template #attribute>
            {{ piivoTranslate(attribute) }}
          </template>
        </i18n>
      </template>
    </pui-dialog>

    <pui-dialog
      v-if="reachedMaxLines"
      ref="maxLinesReachedDialog"
      :showCloseButton="false"
      class="pui-attribute-table__max-lines-reached-dialog"
      transition="slide-up"
      width="40%"
    >
      <div slot="dialog-action" class="dialog-action">
        <pui-button variant="primary" @click="closeMaxLinesReachedDialog()">
          {{ $t('common.attribute_table.max_lines_reached_dialog.buttons.confirm') }}
        </pui-button>
      </div>

      <pui-error picto="mdi-alert" class="max-lines-reached-dialog-message">
        <i18n path="common.attribute_table.max_lines_reached_dialog.message"> </i18n>
      </pui-error>
    </pui-dialog>
  </div>
</template>

<script>
import { createTableCellSelectableMixin } from 'piivo-ui/src/components/table/TableCellSelectableMixin';
import Vue from 'vue';

import { move } from '../../../../utils/array';
import AttributesApi from '../../../common/api/attributes';
import { AttributeTypes } from '../../constants';
import { updateOnTable } from '../../helpers/formsHelper';
import { computeEditableTableColumns } from '../../helpers/tableHelper';
import puiAttributeTableIndicators from './AttributeTableIndicators.vue';

const createValueLine = (columns) =>
  new Array(columns.length)
    .fill(0)
    .map((_, colIndex) =>
      !columns[colIndex] || !columns[colIndex].attribute
        ? null
        : columns[colIndex].attribute.type === AttributeTypes.LINKS
        ? []
        : null
    );

const getTableOptions = (attribute) =>
  (attribute && attribute.options && attribute.options.displayOptions
    ? attribute.options.displayOptions.tableOptions
    : {}) || {};

/**
 * Width for the drag handle cell
 */
const DRAG_HANDLE_WIDTH = '30px';

/**
 * Initial dnd state
 */
const INITIAL_DND = JSON.stringify({
  row: -1,
  target: -1,
  insertIndicator: {
    row: -1,
    y: -1,
  },
  ghostRowIndicator: {
    y: -1,
    height: -1,
  },
});

/**
 * Row drop effect for native dnd
 */
const DROP_EFFECT = 'move';

/**
 * Table component.
 */
export default {
  name: 'PuiAttributeTable',
  components: {
    puiAttributeTableIndicators,
  },
  mixins: [
    createTableCellSelectableMixin({
      currentCellChangeCbName: 'onCurrentCellChange',
      itemsName: 'allValues',
      enableTab: true,
      enableEnter: true,
      enableArrows: false,
    }),
  ],
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    values: {
      type: Array,
      default: () => [],
    },
    loadOptionsFunction: {
      type: Function,
      default: null,
    },
    tabindex: {
      type: [String, Number],
      default: null,
    },
    allowExtendedDisplayMode: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activateValidationMessages: {
      type: Boolean,
      default: true,
    },
    lazyOptions: {
      type: Boolean,
      default: false,
    },
    /**
     * Import function for external link.
     * Receives an array of objects in which to iterate to upload, returns all the imported files
     *
     * @type {(
     * attribute: Attribute,
     * tempFiles: F[],
     * onFileUploadedCb: (tempFile: F, f: any) => void,
     * provideCancelCb: (cancelCb: (tempId?: string) => void) => void
     * ) => Promise<object[]>}
     **/
    importExternalFiles: {
      type: Function,
      default: null,
    },
    /**
     * If attribute search can be displayed
     */
    activateSearch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const columns = getTableOptions(this.attribute).columns || [];
    computeEditableTableColumns(columns);

    let lineId = 0;

    // We need the values as data so they will be reactive
    // Don't declare as const since it will become this.internalValues,
    // and this.internalValues will be mutated
    let internalValues = this.values && Array.isArray(this.values) ? this.values : [];
    // Add ids to each line to use as keys in render
    internalValues = internalValues.map((l) => {
      this.$set(l, '_id', ++lineId);
      return l;
    });

    const extraLineValue = createValueLine(columns);
    this.$set(extraLineValue, '_id', ++lineId);

    return {
      internalValues,
      extraLineValue,
      displayError: false,
      errorMessage: '',
      isImporting: false,
      // Counter for line keys. The lines don't have to be ordered
      // by the counter value. The keys just need to be unique
      lineId,
      /**
       * Index of the row with cell/attribute focus, or with an open menu
       */
      focusedRowIndex: -1,
      // Dragging
      dnd: JSON.parse(INITIAL_DND),
      DRAG_HANDLE_WIDTH,
    };
  },
  computed: {
    /**
     * @returns {object} table options
     */
    tableOptions() {
      return getTableOptions(this.attribute);
    },
    /**
     * @returns {object[]} table columns from the attribute
     */
    columns() {
      const columns = getTableOptions(this.attribute).columns || [];
      computeEditableTableColumns(columns);
      return columns;
    },
    /**
     * @returns {object[][]} all the value lines including the "extra" line
     */
    allValues() {
      const arr = [...this.internalValues];
      if (!this.reachedMaxLines) {
        arr.push(this.extraLineValue);
      }

      return arr;
    },
    /**
     * @returns {number} the index of the last visible column
     */
    firstVisibleColumnIndex() {
      return [...this.columns].findIndex((col) => col.visible);
    },
    /**
     * @returns {number} the index of the last visible column
     */
    lastVisibleColumnIndex() {
      const index = [...this.columns].reverse().findIndex((col) => col.visible);
      return index >= 0 ? this.columns.length - 1 - index : index;
    },
    /**
     * @returns {Number} the max lines
     */
    maxLines() {
      if (
        typeof this.attribute.options.maximum !== 'number' ||
        this.attribute.options.maximum <= 0
      ) {
        return -1;
      }

      return this.attribute.options.maximum;
    },
    /**
     * @returns {number} the index of the extra line or -1 if not displayed
     */
    extraLineIndex() {
      return this.reachedMaxLines ? -1 : this.allValues.length - 1;
    },
    /**
     * @returns {boolean} if the max number of lines has been reached
     */
    reachedMaxLines() {
      return this.maxLines === -1 ? false : this.internalValues.length >= this.maxLines;
    },
  },
  watch: {
    /**
     * Update our interval value to be the prop array
     */
    values(newValues) {
      // Don't declare as const since it will become this.internalValues,
      // and this.internalValues will be mutated
      let internalValues = newValues && Array.isArray(newValues) ? newValues : [];
      internalValues = internalValues.map((line) => {
        if (!line._id) {
          this.$set(line, '_id', ++this.lineId);
        }
        return line;
      });
      this.internalValues = internalValues;
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    piivoTranslateLabel(value) {
      return Vue.filter('piivoTranslateLabel')(value);
    },
    /**
     * Called when a draggable element is clicked. This should include draggable element children,
     * even if they are not directly draggable since the event will bubble.
     * @param {number} valueIndex - index of the row
     */
    onDraggableElementClick(valueIndex) {
      if (this.tableOptions.showOrder && valueIndex < this.internalValues.length) {
        this.dnd.row = valueIndex;
      } else {
        this.dnd.row = -1;
      }
    },
    /**
     * Called when a drag is started on a drag cell
     * @param {DragEvent} e - dragstart event
     * @param {number} valueIndex - line index of the cell
     */
    onDragStart(e, valueIndex) {
      // Set transparent image as ghost image
      const ghost = new Image();
      ghost.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAUEBAAAACwAAAAAAQABAAACAkQBADs=';
      e.dataTransfer.setDragImage(ghost, 0, 0);

      // Setting data allows dragging/dropping
      e.dataTransfer.setData('text/html', null); // Cannot be empty string
      e.dataTransfer.effectAllowed = DROP_EFFECT;

      this.dnd.row = valueIndex;
    },
    /**
     * Called when a drag is ended
     */
    onDragEnd() {
      this.dnd = JSON.parse(INITIAL_DND);
    },
    /**
     * Called when a drag enters a drag cell, drag indicator or table row
     * @param {DragEvent} e - dragenter event
     */
    onDragEnter(e) {
      if (this.dnd.row === -1) {
        return;
      }
      e.dataTransfer.dropEffect = DROP_EFFECT;
      e.preventDefault();
    },
    /**
     * Called when a drag passes over a drag cell or table row
     * @param {DragEvent} e - dragover event
     * @param {number} valueIndex - line index of the cell
     */
    onDragOver(e, valueIndex) {
      if (this.dnd.row === -1) {
        return;
      }
      e.dataTransfer.dropEffect = DROP_EFFECT;
      e.preventDefault();

      // Mouse client is relative to target's getBoundingClientRect
      // But the indicators are relative to our root element
      const targetEl = this.$refs[`row_${valueIndex}`][0].$el;
      const targetRect = targetEl.getBoundingClientRect();
      const rectOffsetY = this.$el.getBoundingClientRect().top;

      const mouseOffsetY = e.clientY - targetRect.top;
      if (this.internalValues.length === valueIndex || mouseOffsetY <= targetRect.height / 2) {
        // Drag over the upper part of the row: insert at current row
        this.dnd.target = valueIndex;
        this.dnd.insertIndicator.y = targetRect.top - rectOffsetY;
      } else {
        // Drag over the lower part of the row: insert after current row
        this.dnd.target = valueIndex + 1;
        this.dnd.insertIndicator.y = targetRect.bottom - rectOffsetY;
      }

      this.dnd.ghostRowIndicator.y = e.clientY - rectOffsetY;
      // Update the height of the ghost with the row's height, since each row could have a different height
      this.dnd.ghostRowIndicator.height = targetRect.height;
    },
    /**
     * Called when a drag is dropped on a drag indicator or a table drop
     * @param {DragEvent} e - drop event
     */
    onDrop(e) {
      if (this.dnd.row === -1 || this.dnd.target === -1 || this.dnd.row === this.dnd.target) {
        return;
      }
      e.preventDefault();
      move(this.dnd.row, this.dnd.target, this.internalValues);
      this.$emit('valueChanged', this.internalValues);
    },
    /**
     * Removes a line
     *
     * @param {number} valueIndex - the index of the value to remove
     */
    removeLine(valueIndex) {
      if (valueIndex >= 0 && valueIndex < this.internalValues.length) {
        this.internalValues.splice(valueIndex, 1);
        this.$emit('valueChanged', this.internalValues);
      }
    },
    /**
     * Duplicates a line
     *
     * @param {number} valueIndex - the index of the value to duplicate
     */
    duplicateLine(valueIndex) {
      if (valueIndex >= 0 && valueIndex < this.internalValues.length) {
        const value = JSON.parse(JSON.stringify(this.internalValues[valueIndex]));
        this.$set(value, '_id', ++this.lineId);
        if (valueIndex === this.internalValues.length - 1) {
          this.internalValues.push(value);
        } else {
          this.internalValues.splice(valueIndex + 1, 0, value);
        }
        this.$emit('valueChanged', this.internalValues);
      }
    },
    /**
     * Closes the clear table confirmation dialog
     */
    closeClearTableDialog() {
      this.$refs.clearDialog.close();
    },
    /**
     * Opens the clear table confirmation dialog
     */
    requestClearTable() {
      if (this.$refs.clearDialog) {
        this.$refs.clearDialog.open();
      }
    },
    /**
     * Clears all the values
     */
    confirmClearTable() {
      this.closeClearTableDialog();
      this.internalValues = [];
      this.$emit('valueChanged', this.internalValues);
    },
    /**
     * Handles the search button clicked
     */
    onSearchClicked() {
      if (this.reachedMaxLines) {
        if (this.$refs.maxLinesReachedDialog) {
          this.$refs.maxLinesReachedDialog.open();
        }
      } else {
        this.$emit('searchRequested', {
          attribute: this.attribute,
          maxSelection: this.maxLines - this.internalValues.length,
          maxSelectionMessageKey: 'common.attribute_table.search.max_lines_reached',
        });
      }
    },
    /**
     * Closes the max lines reached dialog
     */
    closeMaxLinesReachedDialog() {
      if (this.$refs.maxLinesReachedDialog) {
        this.$refs.maxLinesReachedDialog.close();
      }
    },
    /**
     * Opens the file input
     */
    onClickImport() {
      this.$refs.importFile.click();
    },
    /**
     * Handles a file selection change
     *
     * @param {*} event
     */
    async onImportFile(event) {
      try {
        const file = event.target.files[0];
        if (!file) {
          this.isImporting = false;
          return;
        }

        // Clear file, so we can reselect the same file
        this.$refs.importFile.value = null;

        this.displayError = false;
        this.isImporting = true;

        const response = await AttributesApi.importAttributeValue(this.attribute.itemId, file);
        const result = [];
        this.columns.forEach((column) => {
          response.body.value.forEach((line, index) => {
            line.forEach((columnValue, colIndex) => {
              if (columnValue.attributeAlias === column.alias) {
                if (!result[index]) {
                  this.$set(result, index, []);
                }
                this.$set(result[index], colIndex, columnValue.value);
              }
            });
          });
        });

        this.internalValues = result;
        this.$emit('valueChanged', this.internalValues);
      } catch (error) {
        if (error.status === 400) {
          this.errorMessage = this.$t(`common.attribute_table.import_error.${error.body.message}`);
        } else {
          this.errorMessage = this.$t(`common.attribute_table.import_error.Generic`);
        }
        this.displayError = true;
      }
      this.isImporting = false;
    },
    /**
     * Handles the current cell change
     *
     * @param {object} oldCell - ref to the old current cell
     * @param {object} currentCell - ref to the current cell
     */
    onCurrentCellChange(oldCell, currentCell) {
      if (currentCell) {
        if (oldCell && oldCell !== currentCell) {
          oldCell.blur();
        }
        currentCell.focus();

        if (this.tableOptions.showOrder && this.currentPosition.y < this.internalValues.length) {
          this.dnd.row = this.currentPosition.y;
        } else {
          this.dnd.row = -1;
        }
      }
    },
    /**
     * @returns {object} class object for a cell
     *
     * @param {number} x - the x coordinate of the cell
     * @param {number} y - the y coordinate of the cell
     */
    cellClass(x, y) {
      return {
        'pui-attribute-table__cell': true,
        selected:
          this.currentPosition && this.currentPosition.x === x && this.currentPosition.y === y,
        'sticky-cell': this.tableOptions.stickyFirstColumn && x === this.firstVisibleColumnIndex,
        draggable: this.dnd.row === y,
      };
    },
    /**
     * @param {number} x - x coordinate of the cell
     * @param {number} y - y coordinate of the cell
     * @returns {String} value to use as ref name for an attribute panel
     */
    getRowCellName(x, y) {
      return `attr_panel_${x}_${y}`;
    },
    /**
     * Called when a cell is focused
     * @param {number} x - x coordinate of the cell
     * @param {number} y - y coordinate of the cell
     */
    onCellFocused(x, y) {
      if (this.tableOptions.showOrder && y < this.internalValues.length) {
        this.dnd.row = y;
      } else {
        this.dnd.row = -1;
      }
      this.currentPosition = { x, y };
      this.focusedRowIndex = y;
    },
    /**
     * Updates the value in the table
     *
     * @param {number} columnIndex - index of the column changed
     * @param {number} valueIndex - index of the line changed
     * @param {*} value - the new value
     */
    updateValue(columnIndex, valueIndex, value) {
      if (valueIndex === this.internalValues.length) {
        this.$set(this.extraLineValue, columnIndex, value);
        this.internalValues.push(this.extraLineValue);
        this.extraLineValue = createValueLine(this.columns);
        this.$set(this.extraLineValue, '_id', ++this.lineId);
      } else {
        this.$set(this.internalValues[valueIndex], columnIndex, value);
      }

      const currentAttribute = this.columns[columnIndex].attribute;
      if (currentAttribute) {
        // Update only the cells on the same line by using the same 'valueIndex'
        updateOnTable(
          this.attribute,
          (cbAttr, cbAttrColIndex) => {
            this.loadCellOptions(cbAttrColIndex, valueIndex);
          },
          currentAttribute,
          [currentAttribute.itemId]
        );
      }
    },
    /**
     * Handles the input event of an attribute panel
     *
     * @param {number} columnIndex - index of the column changed
     * @param {number} valueIndex - index of the line changed
     * @param {*} value - the new value
     */
    valueInput(columnIndex, valueIndex, value) {
      this.updateValue(columnIndex, valueIndex, value);

      this.$emit('valueInput', this.internalValues);
    },
    /**
     * Handles the change event of an attribute panel
     *
     * @param {number} columnIndex - index of the column changed
     * @param {number} valueIndex - index of the line changed
     * @param {*} value - the new value
     */
    valueChanged(columnIndex, valueIndex, value) {
      this.updateValue(columnIndex, valueIndex, value);

      this.$emit('valueChanged', this.internalValues);
    },
    /**
     * Handles attribute key enter
     *
     * @param {number} columnIndex - index of the column
     * @param {number} valueIndex - index of the line
     */
    onKeyEnter(columnIndex, valueIndex) {
      const prevLength = this.internalValues.length;
      const wasLast = valueIndex === prevLength;

      // Wait for value change
      // If the previous line is now the second to last line, move down
      setTimeout(() => {
        if (
          wasLast &&
          prevLength + 1 === this.internalValues.length &&
          valueIndex === this.internalValues.length - 1
        ) {
          this.moveDown();
        }
      }, 10);
    },
    /**
     * Loads the options of the given cell, if it's a LINKS attribute
     *
     * @param {number} columnIndex - column of the cell
     * @param {number} valueIndex - line of the cell
     */
    loadCellOptions(columnIndex, valueIndex) {
      const column = this.columns[columnIndex];

      if (column.attribute && column.attribute.type !== AttributeTypes.LINKS) {
        return;
      }

      const attrPanelRef = this.getRowCellName(columnIndex, valueIndex);
      if (this.$refs[attrPanelRef] && this.$refs[attrPanelRef][0]) {
        this.$refs[attrPanelRef][0].loadOptions();
      }
    },
    // EXPOSED
    /**
     * Triggers loadOptions for all the links in the table
     */
    loadOptions() {
      this.allValues.forEach((_, valueIndex) => {
        this.columns.forEach((_, columnIndex) => {
          this.loadCellOptions(columnIndex, valueIndex);
        });
      });
    },
  },
};
</script>
