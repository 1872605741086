export const PersistKeys = {
  /**
   * Set this key to "true" in your module's state
   * to mark that it should be kept preserved:
   * - when the user's data is cleared
   * - in a persistent storage
   */
  PRESERVE_STATE_ON_CLEAR: '__preserve_state_on_clear__',
  /**
   * Root state flag that indicates whether the store has been restored or not
   */
  STATE_IS_RESTORED: '__restored__',
  /**
   * Store module version
   */
  MODULE_VERSION: '__module_version__',
};
