<template>
  <pui-common-select
    ref="editor"
    :value="getValue"
    :disabled="editor.disabled"
    :taggable="false"
    :multiple="false"
    :options="editor.options"
    :display="editor.display"
    @select="onValueChanged"
    @blur="onBlur"
  />
</template>
<script>
import TableAttributeEditorMixin from './TableAttributeEditorMixin';

export default {
  components: {},
  mixins: [TableAttributeEditorMixin],
};
</script>
