<template>
  <div class="com-options-error com-options-error__root">
    <pui-flex
      v-if="isLoadError"
      class="com-options-error__container"
      justifyContent="center"
      alignItems="center"
      flex="1"
    >
      <i class="mdi mdi-alert options-error__icon"></i>
      <pui-flex direction="column" class="com-options-error__message container">
        <span class="com-options-error__message primary">
          {{ $t('common.attribute_panel.error.load_options.primary') }}
        </span>
        <span class="com-options-error__message secondary" @click.stop="loadOptions">
          {{ $t('common.attribute_panel.error.load_options.secondary') }}
        </span>
      </pui-flex>
    </pui-flex>
    <span v-else-if="noOptionsMessage">{{ noOptionsMessage }}</span>
  </div>
</template>

<script>
export default {
  name: 'NoOptions',
  props: {
    /**
     * If there was an error loading the options
     */
    isLoadError: {
      type: Boolean,
      default: false,
    },
    /**
     * Message if no elements
     */
    noOptionsMessage: {
      type: String,
      default: null,
    },
    loadOptions: {
      type: Function,
      required: true,
    },
  },
};
</script>
