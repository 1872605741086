import { PersistKeys } from '../plugins/persistence';

export const UPDATE_AUTH = 'UPDATE_AUTH';
export const UPDATE_USER = 'UPDATE_USER';
export const USER_LOGOUT = 'USER_LOGOUT';
export const SET_LAST_LOGIN_TYPE = 'SET_LAST_LOGIN_TYPE';

/**
 * @returns default auth state
 */
function getDefaultAuthState() {
  return {
    [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
    isLoggedIn: false,
    accessToken: null,
    refreshToken: null,
    issued: null,
    expires: null,
    // Do not include lastLoginType in default state since
    // its default value should never overwrite the current value
  };
}

/**
 * @returns default user state
 */
function getDefaultUserState() {
  return {
    [PersistKeys.PRESERVE_STATE_ON_CLEAR]: true,
    userName: null,
    displayName: null,
    language: null,
    extendedProperties: {},
    permissions: [],
  };
}

export const CoreModule = {
  state: {
    auth: { ...getDefaultAuthState(), lastLoginType: null },
    user: getDefaultUserState(),
  },
  getters: {
    user: (state) => state.user,
    lastLoginType: (state) => state.auth.lastLoginType,
  },
  actions: {
    /**
     * Handles user logout
     */
    [USER_LOGOUT](context) {
      Object.assign(context.state.auth, getDefaultAuthState());
      Object.assign(context.state.user, getDefaultUserState());
    },
  },
  mutations: {
    [UPDATE_AUTH](state, auth) {
      state.auth = auth;
    },
    [UPDATE_USER](state, user) {
      state.user = user;
    },
    [SET_LAST_LOGIN_TYPE](state, type) {
      state.auth.lastLoginType = type;
    },
  },
};
