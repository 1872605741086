/**
 *
 * @param str - file name string
 * @returns the extension of the string
 */
export const getExtension = (str: string): string => {
  const ext = /.*(\.[^/]+)$/.exec(str)?.[1] || '';
  if (ext) {
    return ext.substring(1);
  }
  return '';
};

/**
 * Utils function to convert byte to mo
 * @param number - number to convert
 * @returns number converted
 */
export function convertByteToMo(number: string): string {
  const parsed = parseInt(number) / 1024 / 1024;
  return parsed.toFixed(2);
}

/**
 *
 * @param files - input files
 * @param opts - filter options
 * @param opts.maxSize - max size to allow. Set to 0 to disabled
 * @param opts.extensions - extensions to allow. Leave empty to disabled
 * @returns the files that satisfy all options
 */
export const filterFiles = (
  files: File[],
  opts: { maxSize: number; extensions: string[] }
): File[] => {
  return files.filter(
    (file) =>
      (!opts.maxSize || file.size <= opts.maxSize) &&
      (!opts.extensions.length || opts.extensions.some((ext) => file.name.endsWith(ext)))
  );
};
