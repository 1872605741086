import { install } from './install';

export default class HelperManager {
  constructor(options = {}) {
    this.helpers = Object.create({});
  }

  getHelper(helperName) {
    const helper = this.helpers[`$${helperName}`];
    return helper || null;
  }

  addHelper(helperName, helper) {
    if (!this.helpers[`$${helperName}`]) {
      this.helpers[`$${helperName}`] = helper;
    }
  }
}

HelperManager.install = install;

if (window && window.Vue) {
  window.Vue.use(HelperManager);
}
