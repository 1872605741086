import moment from 'moment';
import numeral from 'numeral';

import services from '../services';
import { I18nManager } from '../services/i18nManager';

/**
 * Translate a piivo object with the user language.
 * @param {Object} element : The piivo object.
 * @returns {String} The piivo object translation for the user language.
 */
export const piivoTranslate = (element) => {
  const translated = '';
  if (element && element.label) {
    const selectedLocale = services.getService('languages').getSelectedLocale();
    if (selectedLocale && typeof element.label[selectedLocale] === 'string') {
      return element.label[selectedLocale];
    }
    return element.label[services.getService('languages').getFallbackLocale()];
  }
  return translated;
};

/**
 * Translate a label with the user language.
 * @param {Object} label : The label object.
 * @returns {String} The label translation for the user language.
 */
export const piivoTranslateLabel = (label) => {
  const translated = '';
  if (label) {
    const selectedLocale = services.getService('languages').getSelectedLocale();
    if (selectedLocale && typeof label[selectedLocale] === 'string') {
      return label[selectedLocale];
    }
    return label[services.getService('languages').getFallbackLocale()];
  }
  return translated;
};

/**
 * Format a date with a format pattern.
 * @param {Date} date : The date to format.
 * @param {String} format : The format pattern.
 * @returns {String} the formatted date.
 */
export const abstractDate = (date, format) => {
  return moment(date).format(format);
};

/**
 * Format a number with a format pattern
 * @param {Number} number : The number to format.
 * @param {String} format : The format pattern.
 * @returns {String} the formatted number.
 */
export const abstractNumber = (number, format) => {
  return numeral(number).format(format);
};

/**
 * Format a date to ISO format.
 * @param {Date} date : The date to format.
 * @returns {String} the formatted date.
 */
export const isoDate = (date) => {
  if (!date) {
    return null;
  }
  return moment(date).format('YYYY-MM-DD');
};

/**
 * Format a date to full ISO format.
 * @param {Date} date : The date to format.
 * @returns {String} the formatted date.
 */
export const isoDateFull = (date) => {
  if (!date) {
    return null;
  }
  return moment(date).toISOString();
};

/**
 * Display boolean value (transform into String).
 * @param {Boolean} booleanValue : Value to transform.
 * @returns {String} the transformed value.
 */
export const displayBoolean = (booleanValue) => {
  return booleanValue === true ? 'OUI' : 'NON';
};

export const piivoDate = (date) => {
  // Formatter has to be built every time, to make sure
  // the locales are up to date
  const locales = [
    services.getService('languages').getSelectedLocale(),
    services.getService('languages').getFallbackLocale(),
  ].map((locale) => I18nManager.getI18nLocale(locale));

  const dateTimeFormatter = new Intl.DateTimeFormat(locales, {
    dateStyle: 'short',
  });

  return dateTimeFormatter.format(date);
};

export const piivoDateTime = (date) => {
  // Formatter has to be built every time, to make sure
  // the locales are up to date
  const locales = [
    services.getService('languages').getSelectedLocale(),
    services.getService('languages').getFallbackLocale(),
  ].map((locale) => I18nManager.getI18nLocale(locale));

  const dateTimeFormatter = new Intl.DateTimeFormat(locales, {
    dateStyle: 'short',
    timeStyle: 'medium',
  });

  return dateTimeFormatter.format(new Date(date));
};
