<template>
  <pui-input
    ref="editor"
    type="text"
    :disabled="editor.disabled"
    :value="getValue"
    :textarea="editor.textarea"
    @change="onValueChanged"
    @blur="onBlur"
  >
  </pui-input>
</template>
<script>
import TableAttributeEditorMixin from './TableAttributeEditorMixin';

export default {
  components: {},
  mixins: [TableAttributeEditorMixin],
};
</script>
