/**
 * Moves the item at the source index, to the target index.
 * **Mutates** the array.
 * This is **NOT** the same as swapping indexes.
 * @example
 * move(0, 1, [0, 1, 2]); // => [0, 1, 2]
 * move(0, 2, [0, 1, 2]); // => [1, 0, 2]
 *
 * @param sourceIndex - the index of the item to move
 * @param targetIndex - the index to which to move BEFORE the move
 * @param items - array of items
 */
export const move = (sourceIndex: number, targetIndex: number, items: unknown[]) => {
  const normalizedTargetIndex = Math.min(targetIndex, items.length);

  // If the source is before the target, the fact that we remove it will
  // decrement the new targetIndex. So when we insert, we need to insert
  // at (targetIndex - 1)
  const offset = sourceIndex < targetIndex ? -1 : 0;

  // Remove the source item
  const sourceItem = items.splice(sourceIndex, 1)[0];
  // And then insert it at the target
  items.splice(normalizedTargetIndex + offset, 0, sourceItem);
};

/**
 * Always returns an array
 *
 * @param arr - the input nullable element or array
 * @returns an array containing the element if not nullish or the same array
 */
export function ensureArray<T = unknown>(arr: T): T[] {
  let ret: T | T[] = arr ?? ([] as NonNullable<T>[]);
  ret = Array.isArray(ret) ? ret : ([ret] as NonNullable<T>[]);
  return ret;
}

/**
 * Chunks an array
 *
 * @param arr - the original array
 * @param size - the size of the chunks
 * @returns array of chunks
 */
export function chunk<T = unknown>(arr: T[], size: number): T[][] {
  if (size <= 0) {
    return [arr];
  }

  const res: T[][] = [];

  for (let i = 0; i < arr.length; i += size) {
    res.push(arr.slice(i, i + size));
  }

  return res;
}
