// Table helper
import { getFormattingManager } from 'piivo-poster-engine/src/lib/services/formattingManager';
import Vue from 'vue';

import services from '../../../core/services';

/**
 * Compute columns table definition (manage alias, formatters, permissions etc.).
 * @param {Array} columns : Columns definition
 * @param {Map<string, Object>} attributeMap - map of attribute aliases to attributes
 */
export const computeTableColumns = (columns, attributeMap) => {
  // Compute columns
  if (!columns) {
    return;
  }

  for (const column of columns) {
    // Add header translator
    column.headerTranslator = (value) => Vue.filter('piivoTranslateLabel')(value);

    // Add formatter to columns
    if (
      attributeMap != null &&
      attributeMap.has(column.alias) &&
      attributeMap.get(column.alias) === 'Date'
    ) {
      column.formatter = 'Date';
    }

    // Get formatter functions for formatter names
    if (typeof column.formatter === 'string') {
      column.formatter = {
        value: getFormattingManager().getFormatter(column.formatter),
      };
    } else if (column.formatter != null && column.formatter.alias != null) {
      column.formatter.value = getFormattingManager().getFormatter(column.formatter.alias);
    }

    // Update property name with alias
    column.value = column.alias;

    // Manage column permission
    const permission = column.permission;
    if (permission != null) {
      let visibility = column.visible != null ? column.visible : true;

      // Add permission visibility
      if (permission.length > 1 && permission[0] === '!') {
        // Test inversion (!permission_name)
        visibility =
          visibility && !services.getService('auth').hasPermission(permission.substring(1));
      } else {
        visibility = visibility && services.getService('auth').hasPermission(permission);
      }

      column.visible = visibility;
    }
  }
};

/**
 * Compute columns table definition (manage alias, permissions etc.).
 * @param {Array} columns : Columns definition
 * @param {Object} types : Types Object
 */
export const computeEditableTableColumns = (columns) => {
  // Compute columns
  if (!columns) {
    return;
  }

  for (const column of columns) {
    // Add header translator
    column.headerTranslator = (value) => Vue.filter('piivoTranslateLabel')(value);

    // Update property name with alias
    column.value = column.alias;

    // Manage column permission
    const permission = column.permission;
    if (permission != null) {
      let visibility = column.visible != null ? column.visible : true;

      // Add permission visibility
      if (permission.length > 1 && permission[0] === '!') {
        // Test inversion (!permission_name)
        visibility =
          visibility && !services.getService('auth').hasPermission(permission.substring(1));
      } else {
        visibility = visibility && services.getService('auth').hasPermission(permission);
      }

      column.visible = visibility;
    }
  }
};

/**
 * Compute columns sorts from definition (create sort array for table).
 * @param {[sortKey: string, sortDirection: string][]} sorts - Sorts array
 */
export const computeTableSorts = (sorts) => {
  if (!sorts) {
    return [];
  }

  const computedSorts = [];
  sorts.forEach((sort) => {
    const sortEntry = Object.entries(sort)[0];
    if (sortEntry && sortEntry[0] != null && sortEntry[0] != null && sortEntry[1] !== 'none') {
      computedSorts.push(sortEntry);
    }
  });

  return computedSorts;
};

export default { computeTableColumns, computeTableSorts };
