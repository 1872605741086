import Vue from 'vue';

export default {
  props: {
    /**
     * The widget object definition
     */
    widget: {
      type: Object,
      required: true,
    },
    /**
     * The widget layout
     */
    layout: {
      type: Object,
      required: true,
    },
  },
  methods: {
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    piivoTranslateLabel(value) {
      return Vue.filter('piivoTranslateLabel')(value);
    },
  },
};
