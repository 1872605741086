export default {
  props: {
    editor: {
      type: Object,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    clearValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getValue() {
      return this.clearValue ? '' : this.item[this.value];
    },
  },
  methods: {
    onBlur(value) {
      this.$emit('blur', value);
    },
    onValueChanged(newValue) {
      this.$emit('valueChanged', newValue);
    },
    focus() {
      if (this.$refs.editor) {
        this.$refs.editor.focus();
      }
    },
  },
  mounted() {
    this.focus();
  },
};
