import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const browserLang = navigator.language;

export const I18N_INITIAL_FALLBACK_LOCALE = 'fr-FR';

/**
 * The initial default locale is the browser locale, or french.
 * Do not rely on this to always be the default locale.
 * This is only for app startup and should be replaced
 * when the languages for the app are loaded.
 */
export const I18N_INITIAL_DEFAULT_LOCALE = browserLang || I18N_INITIAL_FALLBACK_LOCALE;

const i18n = new VueI18n({
  fallbackLocale: I18N_INITIAL_FALLBACK_LOCALE,
  locale: I18N_INITIAL_DEFAULT_LOCALE,
  messages: {},
});

export default i18n;
