import { getExtensionPoint } from '../extensionPoints';
import { ISortingManager, SortFunction } from './types';

export class SortingManager implements ISortingManager {
  sortFns = new Map<string, SortFunction>();

  /**
   *
   * @param name - the name for which to register the sort function
   * @param sortFn - the sort function
   */
  registerSortFn(name: string, sortFn: SortFunction): void {
    this.sortFns.set(name, sortFn);
  }

  /**
   *
   * @param name - the name of the sort function to retrieve
   * @returns the sort function
   */
  getSortFn(name: string): SortFunction {
    if (!this.sortFns.has(name)) {
      throw Error(`No registered sort function found under "${name}" name`);
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return this.sortFns.get(name)!;
  }

  /**
   *
   * @returns the list of registered sort function names
   */
  getRegisteredSortFnNames(): string[] {
    return [...this.sortFns.keys()];
  }
}

/**
 *
 * @returns the current sorting manager in the extension point
 */
export function getSortingManager(): ISortingManager {
  return getExtensionPoint('core').sortingManager as ISortingManager;
}
