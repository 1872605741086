<template>
  <div class="file-default-viewer">
    <pui-file-thumbnail-viewer
      v-if="previews.length <= 1"
      :src="previews[0]"
      :mode="Mode.SRC"
      :lazy="false"
      :delayDownloadLength="0"
      :preventDownload="false"
      :fallbackText="extension"
      objectFit="contain"
      class="file-default-viewer__thumbnail-viewer single"
    ></pui-file-thumbnail-viewer>
    <pui-flex v-else direction="column" flex="1" class="file-default-viewer__multi-container">
      <pui-flex class="file-default-viewer__thumbnail-viewer-container" flex="1">
        <pui-file-thumbnail-viewer
          v-for="(preview, previewIdx) in previews"
          v-show="previewIdx === currentPreviewIndex"
          :key="preview"
          :src="preview"
          :mode="Mode.SRC"
          :lazy="false"
          :delayDownloadLength="0"
          :preventDownload="false"
          :fallbackText="extension"
          objectFit="contain"
          class="file-default-viewer__thumbnail-viewer multi"
        ></pui-file-thumbnail-viewer>
      </pui-flex>
      <pui-flex justifyContent="center" alignItems="center">
        <pui-button
          :disabled="currentPreviewIndex <= 0"
          picto="mdi-chevron-left"
          class="file-default-viewer__btn-change prev"
          variant="secondary"
          flat
          @click="currentPreviewIndex--"
        />
        <pui-input
          :value="currentPreviewIndex + 1"
          type="text"
          class="file-default-viewer__page-input"
          @change="setCurrentPreviewIndex"
        ></pui-input>
        <span class="file-default-viewer__page-total">/ {{ previews.length }}</span>
        <pui-button
          :disabled="currentPreviewIndex >= previews.length - 1"
          picto="mdi-chevron-right"
          class="file-default-viewer__btn-change next"
          variant="secondary"
          flat
          @click="currentPreviewIndex++"
        />
      </pui-flex>
    </pui-flex>
  </div>
</template>

<script>
import { Mode } from 'piivo-ui/src/components/image/ImageViewer';

export default {
  name: 'PuiDamDefaultViewer',
  props: {
    file: {
      type: Object,
      required: true,
    },
    getMediaUrl: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      Mode,
      currentPreviewIndex: 0,
    };
  },
  computed: {
    /**
     * @returns {string[]} array of preview urls of the file
     */
    previews() {
      const previews = !!this.file && !!this.file.previews ? this.file.previews : [];

      // When in development, when url is absolute, it needs to be changed to a relative url
      // to be proxied so we can send the cookies corresponding to our domain
      if (process.env.NODE_ENV === 'development' && previews[0] && previews[0].startsWith('http')) {
        return previews.map((src) => new URL(src).pathname);
      }

      return previews;
    },
    /**
     * @returns {string} the extension of the file
     */
    extension() {
      return this.file.description ? this.file.description.extension : '' || '';
    },
  },
  watch: {
    /**
     * Watches the current file to reset the current preview
     */
    file(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.currentPreviewIndex = 0;
      }
    },
  },
  methods: {
    /**
     * Updates the current preview index with the user input
     *
     * @param {number} value - the requested page value
     */
    setCurrentPreviewIndex(value) {
      const index = value - 1;
      if (index < 0) {
        this.currentPreviewIndex = 0;
      } else if (index >= this.previews.length - 1) {
        this.currentPreviewIndex = this.previews.length - 1;
      } else {
        this.currentPreviewIndex = index;
      }
    },
  },
};
</script>
