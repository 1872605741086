// Attribute types alias
export enum AttributeTypes {
  DATE = 'Date',
  NUMERIC = 'Numeric',
  LINKS = 'Links',
  BOOLEAN = 'Boolean',
  STRING = 'String',
  LABEL = 'Label',
  EXTERNAL_LINK = 'ExternalLink',
}

export enum DisplayMode {
  Combo = 'Combo',
  Table = 'Table',
  Tiles = 'Tiles',
  Tree = 'Tree',
}

export const ATTRIBUTE_EXTERNAL_LINK_ITEM_META_HEIGHT_PX = 20;

export const ATTRIBUTE_EXTERNAL_LINK_UPLOAD_BUTTON_ITEM_ID = 'upload_button';
