import { TableEditors } from 'piivo-ui/src/components/table/editors';

import puiAttributeTableEditor from '../../attributes/AttributeTableEditor';
import BooleanAttributeEditor from './BooleanAttributeEditor';
import DateAttributeEditor from './DateAttributeEditor';
import LinksAttributeEditor from './LinksAttributeEditor';
import NumericAttributeEditor from './NumericAttributeEditor';
import StringAttributeEditor from './StringAttributeEditor';

export class CommonTableEditors extends TableEditors {
  constructor() {
    super();

    this.registerEditor('String', StringAttributeEditor);
    this.registerEditor('Date', DateAttributeEditor);
    this.registerEditor('Numeric', NumericAttributeEditor);
    this.registerEditor('Boolean', BooleanAttributeEditor);
    this.registerEditor('Links', LinksAttributeEditor);
    this.registerEditor('Attribute', puiAttributeTableEditor);
  }
}
