import i18n, { I18N_INITIAL_DEFAULT_LOCALE, I18N_INITIAL_FALLBACK_LOCALE } from '../i18n';

const PIIVO_LOCALE_SEPARATOR = '_';

const I18N_LOCALE_SEPARATOR = '-';

export class I18nManager {
  /**
   * @param {string} locale - the locale to merge the messages for
   * @param {object} messages - object of messages to merge into existing
   */
  static mergeLocaleMessages(locale, messages) {
    const i18nLocale = I18nManager.getI18nLocale(locale);

    // I18n.mergeLocaleMessage is supposed to receive a single
    // message object, but since it actually merges the whole object,
    // we can use an object with all the messages
    i18n.mergeLocaleMessage(i18nLocale, messages);
  }

  /**
   * @param {string} locale the piivo locale name
   * @returns {string} the locale name according to RFC 5646
   */
  static getI18nLocale(locale) {
    return locale.replace(PIIVO_LOCALE_SEPARATOR, I18N_LOCALE_SEPARATOR);
  }

  /**
   * @param {string} locale the RFC 5646 locale name
   * @returns {string} the locale name used by piivo
   */
  static getPiivoLocale(locale) {
    return locale.replace(I18N_LOCALE_SEPARATOR, PIIVO_LOCALE_SEPARATOR);
  }

  /**
   * Sets the i18n locale
   *
   * @param {string} locale the piivo locale
   */
  static setLocale(locale) {
    const i18nLocale = I18nManager.getI18nLocale(locale);
    i18n.locale = i18nLocale;
  }

  /**
   * Sets the i18n fallback locale
   *
   * @param {string} locale the piivo locale
   */
  static setFallbackLocale(locale) {
    const i18nLocale = I18nManager.getI18nLocale(locale);
    i18n.fallbackLocale = i18nLocale;
  }
}

/**
 * The initial default locale with piivo naming.
 * Do not rely on this to always be the default locale.
 * This is only for app startup and should be replaced
 * when the languages for the app are loaded.
 */
export const INITIAL_DEFAULT_LOCALE = I18nManager.getPiivoLocale(I18N_INITIAL_DEFAULT_LOCALE);

/**
 * The initial fallback locale with piivo naming.
 * Do not rely on this to always be the default locale.
 * This is only for app startup and should be replaced
 * when the languages for the app are loaded.
 */
export const INITIAL_FALLBACK_LOCALE = I18nManager.getPiivoLocale(I18N_INITIAL_FALLBACK_LOCALE);
