<template>
  <pui-attribute-panel
    ref="editor"
    :attribute="editor.options"
    :value="getValue"
    :displayLabel="false"
    :activateSearch="false"
    :activateValidationMessages="false"
    @valueChanged="onValueChanged"
    @searchRequested="onSearchRequested"
    @blur="onBlur"
  ></pui-attribute-panel>
</template>
<script>
import TableEditorMixin from '../table/editors/TableAttributeEditorMixin';
import puiAttributePanel from './AttributePanel';

export default {
  name: 'PuiAttributeTableEditor',
  components: {
    puiAttributePanel,
  },
  mixins: [TableEditorMixin],
  methods: {
    onSearchRequested(attribute) {
      // TODO : Appeler la recherche avec le store.
    },
  },
};
</script>
