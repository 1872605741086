<template>
  <pui-input
    ref="editor"
    v-model="getValue"
    type="number"
    :disabled="editor.disabled"
    :min="editor.min"
    :max="editor.max"
    :step="editor.step"
    @change="onValueChanged"
    @blur="onBlur"
  >
  </pui-input>
</template>
<script>
import TableAttributeEditorMixin from './TableAttributeEditorMixin';

export default {
  components: {},
  mixins: [TableAttributeEditorMixin],
};
</script>
